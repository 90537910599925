import { EditLinkPreview } from '../../../../components/profile/PageEditor/components/PagePreview/components/MobilePagePreview/components/EditLinkPreview/EditLinkPreview'
import { FC } from 'react'
import { FlowpageLink } from './FlowpageLink'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { useGetPreviewLink } from '../../../../hooks/useGetPreviewLink'
import styled from 'styled-components'

const LinksContainer = styled.div`
  align-self: stretch;
  & > div:not(:last-child) {
    margin-bottom: 25px;
  }
`

interface LinksProps {
  links: LinkType[]
  /** Determines whether to show the preview of a link being added, but not yet saved */
  isPreview?: boolean
}

/**
 * Renders a list of links on a Flowpage.
 */
export const Links: FC<LinksProps> = ({ links, isPreview }) => {
  const previewLink = useGetPreviewLink()

  return (
    <LinksContainer>
      {links?.map(link => (
        <FlowpageLink key={link.id} link={link} isPreview={isPreview} previewLink={previewLink} />
      ))}
      {isPreview && <EditLinkPreview />}
    </LinksContainer>
  )
}
