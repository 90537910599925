import { FontFamilies } from '@dtx-company/true-common/src/constants/page'
import { PageGateContainerProps, PageGateContainerState } from '../types'
import { getCustomThemeValues } from '../../../../utils/theme'
import { theme } from '@dtx-company/shared-components/src'
import { useEffect } from 'react'
import { useFlowpageAnalyticsCollector } from '../../../../hooks/useFlowpageAnalyticsCollector'
import { usePageDisplayContext } from '../../../../context/page-display/consumer'

export const usePageGateContainerState = ({
  gate,
  blurredPage
}: Pick<PageGateContainerProps, 'gate' | 'blurredPage'>): PageGateContainerState => {
  const { gateConditionComplete, setGateConditionComplete, isGateCRMFormSubmitted } =
    usePageDisplayContext()
  const { trackCRMWidgetAction } = useFlowpageAnalyticsCollector({
    isPreview: false,
    isEditMode: false
  })

  const isGateCRMForm = Boolean(gate?.type === 'contactCollection')
  const isNBAIdGate = Boolean(gate?.type === 'nbaIdGate')

  const filledOutFormRequirement = Boolean(gate?.actionData?.filledOutFormRequirement)
  const hasCRMFormSuccessMessage = Boolean(gate?.actionData?.successMessage?.enabled)

  const { style, primaryColor, contrastText, baseColor, baseContrastText } = getCustomThemeValues(
    blurredPage.theme
  )

  const backgroundImgUrl = blurredPage.theme?.backgroundImgUrl
  const fontFamily = blurredPage?.theme?.fontFamily ?? FontFamilies.Inter
  const userTheme = {
    ...theme,
    fontFamily: `'${fontFamily}', ${theme.fontFamily}`,
    colors: {
      ...theme.colors,
      flowpage: { style, primaryColor, contrastText, backgroundImgUrl, baseColor, baseContrastText }
    }
  }

  useEffect(() => {
    /* Form was submitted and doesn't have a custom success message set gate as 
      completed. If the form was submitted and has custom success message the 
      gate is set as completed when user closes modal. See Modal onClose prop */
    if (isGateCRMForm && isGateCRMFormSubmitted && !hasCRMFormSuccessMessage) {
      setGateConditionComplete(true)
    }
  }, [isGateCRMForm, isGateCRMFormSubmitted, hasCRMFormSuccessMessage, setGateConditionComplete])

  const handleModalClose = (): void => {
    if (filledOutFormRequirement && !isGateCRMFormSubmitted) return
    else if (isGateCRMForm && !isGateCRMFormSubmitted) {
      // Track user dismissed the modal
      trackCRMWidgetAction({
        link_id: gate?.id,
        actionType: 'CRM_WIDGET_ACTION_TYPE_CLICK_TO_DISMISS_MODAL',
        displayType: gate?.displayType
      })
    }
    setGateConditionComplete(true)
  }

  const wrapperProps = {
    minHeight: 'unset',
    ...(isNBAIdGate && { maxHeight: 'unset' })
  }

  const isGateOptional = gate?.type === 'contactCollection' && !filledOutFormRequirement

  return {
    userTheme,
    handleModalClose,
    filledOutFormRequirement,
    isGateOptional,
    gateConditionComplete,
    isGateCRMFormSubmitted,
    wrapperProps
  }
}
