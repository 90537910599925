import { PlanType } from '@dtx-company/true-common/src/types/planType'

const pilotPlanTypes = [PlanType.PILOT]

/**
 * Indicates if a given planType is for a PILOT plan.
 *
 * PILOT plans are designed to only be used as trials, to push users to then purchase
 * a plan at the end of their trial.
 *
 * Multiple PILOT plans may exist in the future in order to provide trials with different
 * sets of permissions.
 */
export function isPilotPlanType(planType: PlanType): planType is PlanType.PILOT {
  return pilotPlanTypes.includes(planType)
}
