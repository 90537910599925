import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Modal, ModalVariants } from '@dtx-company/shared-components/src'
import {
  PrimaryButton,
  SecondaryButton
} from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { SIZES } from '@dtx-company/true-common/src/constants/mediaQueries'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { trashCanRed } from '@app/code/src/assets'

interface PixelDeleteConfirmationModalProps {
  open: boolean
  handleClose: () => void
  handleDelete: () => Promise<void>
}

export const PixelDeleteConfirmationModal = (
  props: PixelDeleteConfirmationModalProps
): JSX.Element => {
  const modalContentHeight = 227
  return (
    <Modal
      variant={[ModalVariants.SHEET, ModalVariants.DESKTOP]}
      open={props.open}
      onClose={props.handleClose}
      display="block"
      padding="32px"
      minHeight={modalContentHeight}
      height={modalContentHeight}
      width={['100%', null, null, '444px']}
      top="unset"
      zIndex={1200}
      breakpoint={SIZES.desktop - 1}
    >
      <Text variant="display/small" textAlign="center">
        Are you sure you want to delete this pixel?
      </Text>
      <Spacer mb="16px" />
      <Text textAlign="center">
        Once you delete a pixel you need to add it again and enter the entire code snippet.
      </Text>
      <Spacer mb="16px" />
      <Box justifyContent="center">
        <PrimaryButton
          sizeVariant={'medium'}
          label="Cancel"
          width={['50%', null, null, 'auto']}
          padding="20px"
          onClick={props.handleClose}
        />
        <Spacer mr="20px" />
        <SecondaryButton
          color="status.errorDark"
          borderColor="status.errorDark"
          sizeVariant="mobileFullWidth"
          icon={trashCanRed}
          label="Delete"
          width={['50%', null, null, 'auto']}
          padding="20px"
          onClick={props.handleDelete}
        />
      </Box>
    </Modal>
  )
}
