import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { fireAnalyticsEvent } from '../../event-tracking/helpers/fireAnalyticsEvent'
import { isValidDynamicRoute } from '@dtx-company/true-common/src/utils/urls/isValidDynamicRoute'
import { reverseEnumLookup } from '@dtx-company/true-common/src/utils/types/reverseEnumLookup'
import { useCallback } from 'react'
import { useDefaultPathname } from '../useDefaultPathname'
import { useFlowRouter } from '../../hooks/useFlowRouter'
import qs from 'query-string'

const getValidRedirectDestination = (pathname: string, fallback = Routes.CODES): string => {
  const isValidRoute = Boolean(reverseEnumLookup(Routes, pathname)) || isValidDynamicRoute(pathname)
  return isValidRoute ? pathname : fallback
}

export const useHandleFlowcodeUserRedirect = (): (() => Promise<void>) => {
  const router = useFlowRouter()
  const redirectTo = router.getString('redirectTo')
  const decodedRedirectTo = redirectTo ? decodeURIComponent(redirectTo) : ''
  const { url: redirectToUrl, query } = qs.parseUrl(decodedRedirectTo)
  const defaultPathname = useDefaultPathname()

  const handleFlowcodeUserRedirect = useCallback(async (): Promise<void> => {
    const params = router.query
    const scanToOwnParams = { batchId: params.batchId }
    const redirectTo = params.redirectTo?.toString()
    if (params.batchId && !redirectTo) {
      fireAnalyticsEvent('useHandleFlowcodeUserRedirect_params_batchId_no_redirectTo')
      router.push({
        pathname: Routes.STO_GENERIC,
        query: scanToOwnParams
      })
    } else if (decodedRedirectTo) {
      fireAnalyticsEvent('useHandleFlowcodeUserRedirect_decodedRedirectTo')
      const nextPath = getValidRedirectDestination(redirectToUrl)
      router.push({
        pathname: nextPath,
        query: qs.stringify(query)
      })
    } else {
      fireAnalyticsEvent('useHandleFlowcodeUserRedirect_defaultPathname', {
        defaultPathname,
        homepageFeatureFlag: undefined
      })
      router.push(defaultPathname)
    }
  }, [router, decodedRedirectTo, defaultPathname, redirectToUrl, query])

  return handleFlowcodeUserRedirect
}
