import { CONTACT_US_EMAIL } from '@dtx-company/true-common/src/constants/contactUs'
import { FooterTextLink } from '../../FooterLink'
import { HELP_URL } from '@dtx-company/true-common/src/constants/help'
import { MarketingPageRoutes, Routes } from '@dtx-company/true-common/src/constants/routes'

export const desktopFooterData = (flowpageUrl?: string): FooterTextLink[] => {
  const links: FooterTextLink[] = []

  if (flowpageUrl) {
    links.push({
      target: '_blank',
      href: `mailto:${CONTACT_US_EMAIL}?subject=${encodeURI(
        'Report Suspicious Flowpage'
      )}&body=${encodeURI(`I would like to report ${flowpageUrl} as suspicious.`)}`,
      label: 'Report Phishing'
    })
  }

  links.push(
    {
      target: '_blank',
      href: HELP_URL,
      rel: 'noopener noreferrer',
      label: 'Help Center'
    },
    {
      target: '_blank',
      href: `mailto:${CONTACT_US_EMAIL}`,
      label: 'Contact Flowpage'
    },
    {
      href: MarketingPageRoutes.PRIVACY_POLICY,
      rel: 'noopener noreferrer',
      label: 'Privacy Policy'
    },
    {
      href: `${MarketingPageRoutes.PRIVACY_POLICY}#notice-at-collection`,
      rel: 'noopener noreferrer',
      label: 'Notice at Collection'
    },
    {
      href: MarketingPageRoutes.TERMS_OF_USE,
      rel: 'noopener noreferrer',
      label: 'Terms of Use'
    },
    {
      href: Routes.PRIVACY_REQUEST,
      rel: 'noopener noreferrer',
      label: 'Privacy Request'
    }
  )

  return links
}
