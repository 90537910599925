import * as yup from 'yup'
import {
  CreateSlotMutation,
  KvOperation,
  UpdateSlotMutation
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { PixelDataType, SlotType } from '../../../containers/settings/settings.types'
import {
  PixelFormFieldNames,
  PixelFormState,
  PixelProps,
  PixelState,
  UsePixelFormProps
} from './pixel.types'
import { indexInterfaceWithVar } from '@dtx-company/true-common/src/utils/objects'
import {
  sendErrorNotification,
  sendSuccessNotification
} from '@dtx-company/inter-app/src/utils/notifications'
import { useCallback, useState } from 'react'
import {
  useCreateSlotMutation,
  useUpdateSlotMutation
} from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/slots'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

const schema = yup.object().shape({
  name: yup.string().required(),
  src: yup.string().url('Please enter a valid URL').required()
})

export const usePixelForm = ({ name, src, pixelType }: UsePixelFormProps): PixelFormState => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { register, watch, ...fields } = useForm({
    defaultValues: {
      name,
      src,
      pixelType
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange'
  })

  return {
    isSubmitting,
    setIsSubmitting,
    register,
    watch,
    ...fields
  }
}

export function usePixelState({ id, data, mutate, token, pageId }: PixelProps): PixelState {
  const typedData = data as PixelDataType
  const [isEnabled, setIsEnabled] = useState<boolean>(data.isEnabled)
  const {
    setIsSubmitting,
    isSubmitting,
    formState: { errors },
    watch,
    setValue,
    register,
    handleSubmit
  } = usePixelForm({
    name: typedData.name,
    src: typedData.src,
    pixelType: typedData.pixelType
  })
  const values = watch()
  const [detailsOpen, setDetailsOpen] = useState<boolean>(
    values[PixelFormFieldNames.NAME] ? false : true
  )
  const [updateSlotMutation] = useUpdateSlotMutation()
  const [createSlotMutation] = useCreateSlotMutation()
  const handleToggle = useCallback(async (): Promise<void> => {
    const sendPixelToggleRequest = async (): Promise<{ id: string }> => {
      const res = await updateSlotMutation({
        slotId: id,
        input: {
          path: ['isEnabled'],
          value: String(!isEnabled)
        },
        pageId
      })
      const respData = indexInterfaceWithVar(res, 'data')
      return { id: respData ? (respData as UpdateSlotMutation).updateSlot?.id ?? '' : '' }
    }

    if (typedData.isNotSaved) {
      sendErrorNotification('You must save the pixel before using this feature')
      return
    }
    setIsSubmitting(true)
    try {
      await sendPixelToggleRequest()
      events.userToggledPixel({ pixelId: id })
      setIsEnabled(!isEnabled)
    } catch (err) {
      console.error(err)
    }
    setIsSubmitting(false)
  }, [typedData.isNotSaved, pageId, setIsSubmitting, id, isEnabled, updateSlotMutation])

  const onSubmit = async ({ name, src, pixelType }: UsePixelFormProps): Promise<void> => {
    const sendPixelCreateRequest = async (): Promise<{ id: string }> => {
      const payload = {
        pageId,
        slotMetaType: SlotType.SETTINGS_PIXEL,
        value: {
          name,
          src,
          pixelType,
          isEnabled: true
        }
      }

      const res = await createSlotMutation(payload)
      const respDataCreate = indexInterfaceWithVar(res, 'data')
      return {
        id: respDataCreate ? (respDataCreate as CreateSlotMutation).createSlot?.id ?? '' : ''
      }
    }
    const sendPixelUpdateRequest = async (): Promise<{ id: string }> => {
      const payload = {
        slotId: id,
        pageId,
        input: {
          path: [''],
          value: JSON.stringify({
            name,
            src,
            pixelType
          }),
          operation: 'CONCATENATE' as KvOperation
        }
      }

      const res = await updateSlotMutation(payload)
      const respDataUpdate = indexInterfaceWithVar(res, 'data')
      return {
        id: respDataUpdate ? (respDataUpdate as UpdateSlotMutation).updateSlot?.id ?? '' : ''
      }
    }
    setIsSubmitting(true)
    try {
      if (typedData.isNotSaved) {
        const res = await sendPixelCreateRequest()
        events.userCreatedPixel({ pixelId: res.id })
      } else {
        const res = await sendPixelUpdateRequest()
        events.userUpdatedPixel({ pixelId: res.id })
      }
      mutate()
      sendSuccessNotification(
        typedData.isNotSaved ? 'Successfully created pixel' : 'Successfully updated pixel'
      )
      setDetailsOpen(false)
    } catch (err) {
      console.error(err)
      sendErrorNotification(
        typedData.isNotSaved ? 'Could not create pixel' : 'Could not update pixel'
      )
    } finally {
      setIsSubmitting(false)
    }
  }
  const labelText = (values[PixelFormFieldNames.NAME] || `New custom`) + ' pixel'
  const labelTextId = labelText.replace(' ', '')
  const isDirty =
    typedData.name !== values[PixelFormFieldNames.NAME] ||
    typedData.pixelType !== values[PixelFormFieldNames.PixelType] ||
    typedData.src !== values[PixelFormFieldNames.SRC]
  const isSaveButtonDisabled = !values[PixelFormFieldNames.SRC] || isSubmitting || !isDirty

  return {
    labelText,
    labelTextId,
    handleToggle,
    isEnabled,
    handleSubmit,
    onSubmit,
    errors,
    register,
    setDetailsOpen,
    detailsOpen,
    values,
    setValue,
    isSaveButtonDisabled,
    isSubmitting,
    isDirty,
    typedData
  }
}
