import { CustomFooter } from './footer/minimal'
import { CustomHead } from '../head'
import { FC, PropsWithChildren } from 'react'
import { GlobalLayout } from './styles'
import { Meta } from '../../constants/meta'
import { UnauthSlot } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { WhiteLabelFooter } from './footer/WhiteLabelFooter/WhiteLabelFooter'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

/* istanbul ignore next */
const MinimalFooter = dynamic<{ route?: string }>(() =>
  import('./footer/minimal').then(mod => mod.MinimalFooter)
)

/* istanbul ignore next */
const Footer = dynamic<Record<string, unknown>>(() => import('./footer').then(mod => mod.Footer))

interface FlowpageContainerProps {
  page: string
  injectedMeta?: Meta
  minimalFooter?: boolean
  customFooter?: UnauthSlot
  whiteLabelFooter?: boolean
}

/**
 * Layout component that wraps the page content and provides a consistent layout for flowpages.
 *
 * Cloudflare handles X-Robots-Tag response headers on flowcode.com domains.
 * {@link https://dash.cloudflare.com/9324440620675a4b0f76af17523cf642/flowcode.com/rules/transform-rules/modify-response-header/c4055c0ee44549139cce78878fe9ad9e}
 */
export const FlowpageContainer: FC<PropsWithChildren<FlowpageContainerProps>> = ({
  children,
  page,
  injectedMeta,
  minimalFooter,
  customFooter,
  whiteLabelFooter
}) => {
  const router = useRouter()

  return (
    <GlobalLayout disableTopNav navHeight={0} disableSideNav>
      <CustomHead injectedMeta={injectedMeta} page={page} />
      <div style={{ maxWidth: '100vw', gridArea: 'content' }}>{children}</div>
      {!minimalFooter && !customFooter && !whiteLabelFooter && <Footer disableSideNav />}
      {minimalFooter && !customFooter && !whiteLabelFooter && (
        <MinimalFooter route={router.asPath} />
      )}
      {customFooter && <CustomFooter customFooter={customFooter} />}
      {whiteLabelFooter && !customFooter && <WhiteLabelFooter />}
    </GlobalLayout>
  )
}
