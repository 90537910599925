import { SVGProps, memo } from 'react'

function ArrowUpSvg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 11 6"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      width="1em"
      height="1em"
      {...rest}
    >
      <path d="M.75 4.75l4.5-4 4.5 4" fill="none" stroke={color || stroke} strokeWidth={1.5} />
    </svg>
  )
}

export const ArrowUp = memo(ArrowUpSvg)
