import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { FC } from 'react'
import { MarketingPageRoutes } from '@dtx-company/true-common/src/constants/routes'
import { PageGateContainer } from '../../../containers/slug/gate'
import { PageType, UnauthPage } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { PixelHomepageContainer } from '../../../containers/settings/pixel'
import { SEOMetaTagsHeadComponent } from '../../../containers/settings/SEOMetaTagsSettings/SEOMetaTagsHeadComponent'
import { buildPixelList, getSEOMetaTagsLink } from './FlowpageLandingPage.utils'
import { getCurrentPageGate } from '@app/common/src/utils/getCurrentPageGate'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { usePageDisplayContext } from '../../../context/page-display'
import { useRouter } from 'next/router'
import FlowPageContent from '../FlowPageContent'
import Link from 'next/link'
import styled from 'styled-components'

const StyledContent = styled.div`
  margin-top: 100px;
  @media (min-width: 800px) {
    margin-top: 60px;
  }
`
const StyledBanner = styled.img`
  text-align: center;
  display: block;
  margin: 0 auto;
  height: 160px;
  @media (min-width: 374px) {
    height: 200px;
  }
  @media (min-width: 800px) {
    height: 280px;
  }
`
const StyledText = styled.p`
  height: 18px;
  text-align: center;
  font-size: 20px;
`

const StyledButton = styled(Button)`
  display: block;
  width: 200px;
  height: 48px;
  margin: 40px auto 0;
  font-weight: bold;
`

export const FlowpageLandingPage: FC<{
  page: PageType | UnauthPage
  referrer: string | null | undefined
}> = ({ page, referrer }) => {
  const router = useRouter()
  const { slug } = router.query
  const currentPage = useCurrentPage()
  const sentFromProfilePage = currentPage?.slugName === slug

  const { pixels, fbPixels } = buildPixelList(page)
  const seoMetaTagsLink = getSEOMetaTagsLink(page?.links)

  const showPage = sentFromProfilePage ? currentPage : page

  const { isOwnPage, gateConditionComplete } = usePageDisplayContext()
  const pageGate = getCurrentPageGate(currentPage?.links)
  if (!showPage) {
    return (
      <StyledContent>
        <StyledBanner src="/static/404-cloud.svg" alt="404 banner"></StyledBanner>
        <StyledText>{"Nobody's reserved this Flowpage yet."}</StyledText>
        <Link
          href={{
            pathname: MarketingPageRoutes.PAGE,
            query: { slug: router?.query?.slug }
          }}
        >
          <StyledButton color="primary">Claim it Now</StyledButton>
        </Link>
      </StyledContent>
    )
  }
  const showGate = pageGate && isOwnPage && !gateConditionComplete
  return (
    showPage && (
      <>
        {showGate ? (
          <PageGateContainer
            pageLinksLength={showPage?.links?.length ?? 0}
            blurredPage={{
              profileImage: showPage?.profileImage,
              displayName: showPage?.displayName,
              caption: showPage?.caption,
              theme: showPage.theme
            }}
            gate={pageGate}
          />
        ) : (
          <>
            {pixels && <PixelHomepageContainer pixels={pixels} fbPixels={fbPixels} />}
            {seoMetaTagsLink && <SEOMetaTagsHeadComponent link={seoMetaTagsLink} />}
            <FlowPageContent
              preview={false}
              page={showPage}
              isOwnPage={isOwnPage}
              sentFromProfilePage={sentFromProfilePage}
            />
          </>
        )}
      </>
    )
  )
}
