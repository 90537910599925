import { ConditionalLink } from '@app/common/src/components/conditional-link'
import { ElementType, HTMLAttributes, ReactChild, ReactElement } from 'react'
import { FooterAnchor } from './styles'

type FooterLinkProps = {
  as?: ElementType
  href: string
  target?: '_blank'
  rel?: 'noopener noreferrer'
  children: ReactChild
} & HTMLAttributes<Element>

export type FooterTextLink = Omit<FooterLinkProps, 'as' | 'children'> & {
  label: string
}

export const FooterLink = ({
  as: AnchorComponent = FooterAnchor,
  href,
  target,
  rel,
  children,
  ...rest
}: FooterLinkProps): ReactElement => {
  return (
    <ConditionalLink condition={!target} href={href} prefetch={false}>
      <AnchorComponent target={target} href={href} rel={rel} {...rest}>
        {children}
      </AnchorComponent>
    </ConditionalLink>
  )
}
