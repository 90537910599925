import { Box } from '../Box'
import { FC } from 'react'
import { RadioButtonBase } from './styles'
import { RadioButtonProps } from '../../../types'
import { Text } from '../Text'

export const RadioButton: FC<RadioButtonProps> = ({
  checked,
  textComponent,
  disabled = false,
  error = false,
  inputProps,
  label,
  labelProps,
  name,
  onBlur,
  onChange,
  onFocus,
  textVariant = 'body/small',
  value,
  variant = 'lg',
  activeColor,
  ...rest
}: RadioButtonProps): JSX.Element => {
  return (
    <Box display="block" {...rest}>
      <RadioButtonBase
        variant={variant}
        error={error}
        activeColor={activeColor}
        disabled={disabled}
      >
        <input
          type="radio"
          checked={checked}
          disabled={disabled}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          {...inputProps}
        />
        <span className="radio-button_custom" />
        <Text
          variant={textVariant}
          component={textComponent}
          paddingLeft={45}
          color={disabled ? 'secondary.placeholder' : 'initial'}
          {...labelProps}
        >
          {label}
        </Text>
      </RadioButtonBase>
    </Box>
  )
}
