import { gql } from 'graphql-request'

export const UPDATE_SENDGRID_SUBSCRIPTION_MUTATION = gql`
  mutation updateSendgridSubscription($email: String!, $shouldUnsubscribe: Boolean) {
    updateSendgridSubscription(email: $email, shouldUnsubscribe: $shouldUnsubscribe) {
      ok
    }
  }
`

export const UNSUBSCRIBE_HUBSPOT_MUTATION = gql`
  mutation unsubscribeHubspot($email: String!) {
    unsubscribeHubspot(email: $email) {
      ok
    }
  }
`

// Print Store Mutations
export const ADD_PRINT_STORE_SHOPPING_CART_ITEM_WITHOUT_TEMPLATE = gql`
  mutation addShoppingCartItemWithoutTemplate(
    $productId: String!
    $priceId: String!
    $quantity: Int!
    $batchId: String
  ) {
    shoppingCartAddItem(
      productId: $productId
      priceId: $priceId
      quantity: $quantity
      batchId: $batchId
    ) {
      ok
    }
  }
`

export const ADD_PRINT_STORE_SHOPPING_CART_ITEM_WITH_TEMPLATE = gql`
  mutation addShoppingCartItemWithTemplate(
    $productId: String!
    $priceId: String!
    $quantity: Int!
    $batchId: String
    $templateId: String
    $templateDetails: JSONString
  ) {
    shoppingCartAddItem(
      productId: $productId
      priceId: $priceId
      quantity: $quantity
      batchId: $batchId
      templateId: $templateId
      templateDetails: $templateDetails
    ) {
      ok
    }
  }
`

export const ADD_PRINT_STORE_SHOPPING_CART_ITEM_WITH_DH_TEMPLATE = gql`
  mutation addShoppingCartItemWithDhTemplate(
    $batchId: String!
    $dhTemplateId: String!
    $dhProjectId: String!
    $dhProjectCustomization: JSONString
    $priceId: String!
    $productId: String!
    $quantity: Int!
  ) {
    shoppingCartAddItem(
      batchId: $batchId
      dhTemplateId: $dhTemplateId
      dhProjectId: $dhProjectId
      dhProjectCustomization: $dhProjectCustomization
      priceId: $priceId
      productId: $productId
      quantity: $quantity
    ) {
      ok
    }
  }
`

export const EDIT_PRINT_STORE_SHOPPING_CART_ITEM_QTY = gql`
  mutation editShoppingCartItemQty($itemId: String!, $newQuantity: Int!) {
    shoppingCartEditItemQuantity(itemId: $itemId, newQuantity: $newQuantity) {
      ok
    }
  }
`

export const DELETE_PRINT_STORE_SHOPPING_CART_ITEM = gql`
  mutation deleteShoppingCartItem($itemId: String!) {
    shoppingCartDeleteItem(itemId: $itemId) {
      ok
    }
  }
`

export const CONFIRM_AND_DELETE_PRINT_STORE_SHOPPING_CART_ORDER = gql`
  mutation confirmAndDeleteShoppingCartOrder($cartId: String!) {
    shoppingCartOrderPlaced(cartId: $cartId) {
      ok
    }
  }
`

export const CREATE_PRINT_STORE_CHECKOUT = gql`
  mutation createPrintStoreCheckout($checkoutData: CheckoutInput!) {
    printStoreCheckout(checkoutData: $checkoutData) {
      ok
      stripeCheckoutSessionId
    }
  }
`

export const SAVE_PRINT_STORE_DH_PROJECT = gql`
  mutation savePrintStoreDHProject(
    $dhProjectId: String!
    $dhUserId: String!
    $projectTitle: String!
    $sourceTemplateId: UUID!
  ) {
    associateProject(
      dhProjectId: $dhProjectId
      dhUserId: $dhUserId
      projectTitle: $projectTitle
      sourceTemplateId: $sourceTemplateId
    ) {
      ok
    }
  }
`

export const SAVE_PRINT_STORE_BRAND_KIT = gql`
  mutation savePrintStoreBrandKit($brandKit: BrandKitInput!) {
    saveBrandKit(brandKitInput: $brandKit) {
      ok
    }
  }
`

export const EXPORT_PRINT_STORE_DH_PROJECT = gql`
  mutation exportPrintStoreDHProject(
    $dhProjectId: String!
    $exportMethod: ExportMethod!
    $dhProjectData: JSONString!
    $flowcodeId: String!
  ) {
    projectExport(
      dhProjectId: $dhProjectId
      exportMethod: $exportMethod
      dhProjectData: $dhProjectData
      flowcodeId: $flowcodeId
    ) {
      ok
    }
  }
`

// WhiteLabel domains

export const CREATE_REDIRECT_SUBDOMAIN = gql`
  mutation createRedirectSubdomain($subdomain: String!, $rootDomainId: String!) {
    createRedirectSubdomain(subdomain: $subdomain, rootDomainId: $rootDomainId) {
      ok
      redirectSubdomain {
        id
        domain
        timestamp
        isVerified
      }
    }
  }
`

export const DELETE_REDIRECT_SUBDOMAIN = gql`
  mutation deleteRedirectSubdomain($subdomainId: String!) {
    deleteRedirectSubdomain(subdomainId: $subdomainId) {
      ok
    }
  }
`

export const RESTORE_REDIRECT_SUBDOMAIN = gql`
  mutation restoreRedirectSubdomain($subdomainId: String!) {
    restoreRedirectSubdomain(subdomainId: $subdomainId) {
      ok
    }
  }
`

export const REFRESH_REDIRECT_SUBDOMAIN_STATUS = gql`
  mutation refreshRedirectSubdomainStatus($subdomainId: String!) {
    refreshRedirectSubdomainStatus(subdomainId: $subdomainId) {
      isActive
    }
  }
`

export const CREATE_EXTERNAL_DOMAIN = gql`
  mutation createExternalDomain($domain: String!) {
    createExternalDomain(domain: $domain) {
      ok
      externalDomain {
        deletedAt
        domain
        id
        isCoBranded
        isVerified
        productType
        timestamp
      }
    }
  }
`

export const DELETE_EXTERNAL_DOMAIN = gql`
  mutation deleteExternalDomain($domainId: String!) {
    deleteExternalDomain(domainId: $domainId) {
      ok
    }
  }
`

export const SHARE_CODE_SUBDOMAIN = gql`
  mutation shareIthacaAssets(
    $assetType: String!
    $assetIds: [UUID!]!
    $entityPermissions: [EntityPermissionInputType]!
  ) {
    shareIthacaAssets(
      assetType: $assetType
      assetIds: $assetIds
      entityPermissions: $entityPermissions
    ) {
      assetPermissions {
        assetId
        result {
          success
          error
        }
      }
    }
  }
`

export const UNSHARE_CODE_SUBDOMAIN = gql`
  mutation unshareIthacaAssets($assetType: String!, $assetIds: [UUID]!, $entityIds: [UUID]!) {
    unshareIthacaAssets(assetType: $assetType, assetIds: $assetIds, entityIds: $entityIds) {
      assetPermissions {
        assetId
        result {
          success
          error
        }
      }
    }
  }
`

export const TRANSFER_FOLDER_MUTATION = gql`
  mutation transferDirectory($directoryId: ID!, $newOwnerEmail: String!) {
    transferDirectory(directoryId: $directoryId, newOwnerEmail: $newOwnerEmail) {
      ok
    }
  }
`

export const SAVE_CODE_TEMPLATE = gql`
  mutation saveCodeTemplate($input: BatchDesignTemplateInputType) {
    createBatchDesignTemplate(input: $input) {
      ok
      batchTemplate {
        id
        name
      }
    }
  }
`

export const DELETE_CODE_TEMPLATE = gql`
  mutation deleteCodeTemplate($templateId: ID!) {
    deleteBatchDesignTemplate(templateId: $templateId) {
      ok
    }
  }
`
