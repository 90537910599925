import { Routes, allowedPausedAccountRoutes } from '@dtx-company/true-common/src/constants/routes'

/**
 *
 * Determines if the user should be redirected to the paused account page
 * based on the url and the user's permissions
 *
 **/
export function shouldRedirectToPausedAccount({
  url,
  isBillingAdmin,
  isPilot,
  isImpersonating
}: {
  url: string
  isBillingAdmin: boolean
  isPilot: boolean
  isImpersonating: boolean
}): boolean {
  if (isImpersonating) return false

  const userAllowedRoutes =
    !isBillingAdmin && !isPilot && allowedPausedAccountRoutes.user.includes(url as Routes)

  const billingAdminAllowedRoutes =
    isBillingAdmin && !isPilot && allowedPausedAccountRoutes.billingAdmin.includes(url as Routes)

  const pilotPlanBillingAdminAllowedRoutes =
    isBillingAdmin &&
    isPilot &&
    allowedPausedAccountRoutes.pilotPlanBillingAdmin.includes(url as Routes)

  const pilotPlanUserAllowedRoutes =
    !isBillingAdmin && isPilot && allowedPausedAccountRoutes.pilotPlanUser.includes(url as Routes)

  // Do not redirect on these conditions
  const doNotRedirect =
    billingAdminAllowedRoutes ||
    pilotPlanBillingAdminAllowedRoutes ||
    pilotPlanUserAllowedRoutes ||
    userAllowedRoutes

  return !doNotRedirect
}
