import { ErrorType } from '../types/error.types'
import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import {
  FlowcodeColorOptionType,
  FlowcodeThemeType
} from '@app/common/src/types/createFlowcode.types'
import { FlowcodeThemes } from '../constants/flowcode-themes'
import { theme } from '@dtx-company/shared-components/src'

export const DEFAULT_FLOWCODE_NICKNAME = 'My Flowcode'
// intentionally flowcode.com for a cdn redirect
export const DEFAULT_FLOWCODE_DESTINATION = 'https://flowcode.com/p/50JKXdahj'
export const DEFAULT_FLOWCODE_DESTINATION_PREFIX = 'https://flowcode.com/p/50JKX'
export const DEFAULT_FLOWCODE_DESTINATION_LOGGED_IN = 'https://flowcode.com/p/50JKC6pxL'
export const DEFAULT_LOGO_OUTER_PADDING = 2
export const DEFAULT_FLOWCODE_LOGO_DIMENSIONS = {
  WIDTH: 0,
  HEIGHT: 0
}
export const DEFAULT_RIM_TEXT = { top: null, bottom: null }
export const RIM_TEXT_SIZE = 26

const { builder } = theme.colors

export const ALL_USERS_FLOWCODE_COLOR_OPTIONS: FlowcodeColorOptionType[] = [
  {
    label: 'Black',
    color: builder.black,
    id: 'black',
    defaultOnly: false,
    index: 0
  },
  {
    label: 'Grey',
    color: builder.greyLight,
    id: 'grey',
    defaultOnly: true,
    index: 3
  },
  {
    label: 'Dark Grey',
    color: builder.greyDark,
    id: 'greydark',
    defaultOnly: true,
    index: 4
  },
  {
    label: 'Red',
    color: builder.redLight,
    id: 'red',
    defaultOnly: true,
    index: 5
  },
  {
    label: 'Dark Red',
    color: builder.redDark,
    id: 'reddark',
    defaultOnly: true,
    index: 6
  },
  {
    label: 'Magenta',
    color: builder.magentaLight,
    id: 'magenta',
    defaultOnly: true,
    index: 13
  },
  {
    label: 'Dark Magenta',
    color: builder.magentaDark,
    id: 'magentadark',
    defaultOnly: true,
    index: 14
  },
  {
    label: 'Pink',
    color: builder.pinkLight,
    id: 'pink',
    defaultOnly: true,
    index: 17
  },
  {
    label: 'Dark Pink',
    color: builder.pinkDark,
    id: 'pinkdark',
    defaultOnly: true,
    index: 18
  },
  {
    label: 'Purple',
    color: builder.purpleLight,
    id: 'purple',
    defaultOnly: true,
    index: 23
  },
  {
    label: 'Dark Purple',
    color: builder.purpleDark,
    id: 'purpledark',
    defaultOnly: true,
    index: 24
  },
  {
    label: 'Navy',
    color: builder.navyLight,
    id: 'navy',
    defaultOnly: true,
    index: 27
  },
  {
    label: 'Dark Navy',
    color: builder.navyDark,
    id: 'navydark',
    defaultOnly: true,
    index: 28
  },
  {
    label: 'Blue',
    color: builder.blueLight,
    id: 'blue',
    defaultOnly: true,
    index: 31
  },
  {
    label: 'Dark Blue',
    color: builder.blueDark,
    id: 'bluedark',
    defaultOnly: true,
    index: 32
  },
  {
    label: 'Turquoise',
    color: builder.turquoiseLight,
    id: 'turquoise',
    defaultOnly: true,
    index: 35
  },
  {
    label: 'Dark Turquoise',
    color: builder.turquoiseDark,
    id: 'turquoisedark',
    defaultOnly: true,
    index: 36
  },
  {
    label: 'Jade',
    color: builder.jadeLight,
    id: 'jade',
    defaultOnly: true,
    index: 37
  },
  {
    label: 'Dark Jade',
    color: builder.jadeDark,
    id: 'jadedark',
    defaultOnly: true,
    index: 38
  },

  {
    label: 'Pumpkin',
    color: builder.pumpkinLight,
    id: 'pumpkin',
    defaultOnly: true,
    index: 47
  },
  {
    label: 'Dark Pumpkin',
    color: builder.pumpkinDark,
    id: 'pumpkindark',
    defaultOnly: true,
    index: 48
  },
  {
    label: 'Brown',
    color: builder.brownLight,
    id: 'brown',
    defaultOnly: true,
    index: 49
  },
  {
    label: 'Dark Brown',
    color: builder.brownDark,
    id: 'browndark',
    defaultOnly: true,
    index: 50
  }
]

export const HOMEPAGE_AUTO_DESIGN_FLOWCODE_COLOR_OPTIONS: FlowcodeColorOptionType[] = [
  {
    label: 'Black',
    color: builder.black,
    id: 'black',
    defaultOnly: false,
    index: 0
  },
  {
    label: 'Red',
    color: builder.redLight,
    id: 'red',
    defaultOnly: true,
    index: 5
  },
  {
    label: 'Pink',
    color: builder.pinkLight,
    id: 'pink',
    defaultOnly: true,
    index: 17
  },
  {
    label: 'Purple',
    color: builder.purpleLight,
    id: 'purple',
    defaultOnly: true,
    index: 23
  },
  {
    label: 'Blue',
    color: builder.blueLight,
    id: 'blue',
    defaultOnly: true,
    index: 31
  },
  {
    label: 'Turquoise',
    color: builder.turquoiseLight,
    id: 'turquoise',
    defaultOnly: true,
    index: 35
  },
  {
    label: 'Jade',
    color: builder.jadeLight,
    id: 'jade',
    defaultOnly: true,
    index: 37
  },
  {
    label: 'Pumpkin',
    color: builder.pumpkinLight,
    id: 'pumpkin',
    defaultOnly: true,
    index: 47
  }
]

export const FREE_LOGGED_IN_FLOWCODE_COLOR_OPTIONS: FlowcodeColorOptionType[] = [
  {
    label: 'Sky',
    color: builder.skyLight,
    id: 'sky',
    defaultOnly: true,
    index: 33
  },
  {
    label: 'Dark Sky',
    color: builder.skyDark,
    id: 'skydark',
    defaultOnly: true,
    index: 34
  },

  {
    label: 'Peach',
    color: builder.peachLight,
    id: 'peach',
    defaultOnly: true,
    index: 45
  },
  {
    label: 'Dark Peach',
    color: builder.peachDark,
    id: 'peachdark',
    defaultOnly: true,
    index: 46
  }
]

export const PRO_ENTERPRISE_FLOWCODE_COLOR_OPTIONS: FlowcodeColorOptionType[] = [
  {
    label: 'Slate',
    color: builder.slateLight,
    id: 'slate',
    defaultOnly: true,
    index: 1
  },
  {
    label: 'Dark Slate',
    color: builder.slateDark,
    id: 'slatedark',
    defaultOnly: true,
    index: 2
  },

  {
    label: 'Raspberry',
    color: builder.raspberryLight,
    id: 'raspberry',
    defaultOnly: true,
    index: 7
  },
  {
    label: 'Dark Raspberry',
    color: builder.raspberryDark,
    id: 'raspberrydark',
    defaultOnly: true,
    index: 8
  },

  {
    label: 'Coral',
    color: builder.coralLight,
    id: 'coral',
    defaultOnly: true,
    index: 9
  },
  {
    label: 'Dark Coral',
    color: builder.coralDark,
    id: 'coraldark',
    defaultOnly: true,
    index: 10
  },
  {
    label: 'Rose',
    color: builder.roseLight,
    id: 'rose',
    defaultOnly: true,
    index: 11
  },
  {
    label: 'Dark Rose',
    color: builder.roseDark,
    id: 'rosedark',
    defaultOnly: true,
    index: 12
  },

  {
    label: 'Fuschia',
    color: builder.fuchsiaLight,
    id: 'fuschia',
    defaultOnly: true,
    index: 15
  },
  {
    label: 'Dark Fuschia',
    color: builder.fuchsiaDark,
    id: 'fuschiadark',
    defaultOnly: true,
    index: 16
  },

  {
    label: 'Lilac',
    color: builder.lilacLight,
    id: 'lilac',
    defaultOnly: true,
    index: 19
  },
  {
    label: 'Dark Lilac',
    color: builder.lilacDark,
    id: 'lilacdark',
    defaultOnly: true,
    index: 20
  },
  {
    label: 'Violet',
    color: builder.violetLight,
    id: 'violet',
    defaultOnly: true,
    index: 21
  },
  {
    label: 'Dark Violet',
    color: builder.violetDark,
    id: 'violetdark',
    defaultOnly: true,
    index: 22
  },

  {
    label: 'Indigo',
    color: builder.indigoLight,
    id: 'indigo',
    defaultOnly: true,
    index: 25
  },
  {
    label: 'Dark Indigo',
    color: builder.indigoDark,
    id: 'indigodark',
    defaultOnly: true,
    index: 26
  },

  {
    label: 'FlowBlue',
    color: builder.flowblueLight,
    id: 'flowblue',
    defaultOnly: true,
    index: 29
  },
  {
    label: 'Dark FlowBlue',
    color: builder.flowblueDark,
    id: 'flowbluedark',
    defaultOnly: true,
    index: 30
  },

  {
    label: 'Green',
    color: builder.greenLight,
    id: 'green',
    defaultOnly: true,
    index: 39
  },
  {
    label: 'Dark Green',
    color: builder.greenDark,
    id: 'greendark',
    defaultOnly: true,
    index: 40
  },
  {
    label: 'Lime',
    color: builder.limeLight,
    id: 'lime',
    defaultOnly: true,
    index: 41
  },
  {
    label: 'Dark Lime',
    color: builder.limeDark,
    id: 'limedark',
    defaultOnly: true,
    index: 42
  },

  {
    label: 'Orange',
    color: builder.orangeLight,
    id: 'orange',
    defaultOnly: true,
    index: 43
  },
  {
    label: 'Dark Orange',
    color: builder.orangeDark,
    id: 'orangedark',
    defaultOnly: true,
    index: 44
  }
]

/**
 * Available to all users regardless of plan type for Flowcode and Flowcode.
 * See {@link https://app.shortcut.com/flowcode/story/47905}
 */
export const UPDATED_FLOWCODE_COLOR_OPTIONS: FlowcodeColorOptionType[] = [
  {
    label: 'Black',
    color: builder.black,
    id: 'black',
    defaultOnly: false,
    index: 0
  },
  {
    label: 'Dark Grey',
    color: builder.greyDark,
    id: 'greyDark',
    defaultOnly: true,
    index: 1
  },
  {
    label: 'Dark Red',
    color: builder.redDark,
    id: 'redDark',
    defaultOnly: true,
    index: 2
  },
  {
    label: 'Red',
    color: builder.redLight,
    id: 'redLight',
    defaultOnly: true,
    index: 3
  },
  {
    label: 'Pumpkin',
    color: builder.pumpkinLight,
    id: 'pumpkinLight',
    defaultOnly: true,
    index: 4
  },
  {
    label: 'Orange',
    color: builder.orangeLight,
    id: 'orangeLight',
    defaultOnly: true,
    index: 5
  },
  {
    label: 'Magenta',
    color: builder.magentaLight,
    id: 'magentaLight',
    defaultOnly: true,
    index: 6
  },
  {
    label: 'Purple',
    color: builder.purpleLight,
    id: 'purpleLight',
    defaultOnly: true,
    index: 7
  },
  {
    label: 'Dark Blue',
    color: builder.flowblueLight,
    id: 'flowblueLight',
    defaultOnly: true,
    index: 8
  },
  {
    label: 'Blue',
    color: builder.blueLight,
    id: 'blueLight',
    defaultOnly: true,
    index: 9
  },
  {
    label: 'Turquoise',
    color: builder.turquoiseLight,
    id: 'turquoiseLight',
    defaultOnly: true,
    index: 10
  },
  {
    label: 'Green',
    color: builder.jadeDark,
    id: 'jadeDark',
    defaultOnly: true,
    index: 11
  }
]

export const FLOWCODE_THEME_OPTIONS: FlowcodeThemeType[] = [
  {
    dark: false,
    inverted: false,
    id: FlowcodeThemes.LightStandard,
    label: 'light_standard',
    defaultOnly: false,
    name: 'Standard'
  },
  {
    dark: true,
    inverted: false,
    id: FlowcodeThemes.DarkStandard,
    label: 'dark_standard',
    defaultOnly: true,
    name: 'Dark'
  }
]

// folders
export const DEFAULT_FOLDER_ID = '-1'
export const DEFAULT_FOLDER_NAME = 'My Code Collection'

export const MAX_CENTER_IMAGE_SIZE = 5 * 1000000 // 5MB
export const CENTER_IMAGE_SIZE_ERROR: ErrorType = {
  code: 'CENTER_IMAGE_SIZE_ERROR',
  message: 'Image file must be smaller than 5MB.'
}

export const MAX_FILE_UPLOAD_SIZE = 20000000
export const FILE_UPLOAD_SIZE_ERROR: ErrorType = {
  code: 'FILE_UPLOAD_SIZE_ERROR',
  message: 'File must be smaller than 20MB.'
}

export const MAX_FILE_NAME_LENGTH = 128
export const FILE_NAME_LENGTH_ERROR: ErrorType = {
  code: 'FILE_NAME_LENGTH_ERROR',
  message: 'The name of your file must be less than 128 characters.'
}

export const CENTER_IMAGE_NAME_LENGTH_ERROR: ErrorType = {
  code: 'CENTER_IMAGE_NAME_LENGTH_ERROR',
  message: 'The name of your center image must be less than 128 characters.'
}

export const DUPLICATE_FOLDER_NAME_ERROR: ErrorType = {
  code: 'DUPLICATE_FOLDER_NAME_ERROR',
  message: 'A folder with this name already exists. Please choose a different name'
}

export const UNSAFE_FILE_UPLOAD_ERROR: ErrorType = {
  code: 'UNSAFE_FILE_UPLOAD_ERROR',
  message: 'File contains unsafe content.  Please upload a safe file.'
}

export const DUPLICATE_NICKNAME_BULK_ERROR: ErrorType = {
  code: 'DUPLICATE_NICKNAME_BULK_ERROR',
  message:
    'One of the flowcodes has a nickname that already exists within this folder. Please update your csv file so that no duplicate nicknames are used.'
}

// code management
export const NO_FLOWCODES_MESSAGE = 'No results matched your search'

export const DEFAULT_CONFIG_NAME = 'DEFAULT_BEARBRICK'

export enum CreateFlowcodeTypes {
  ONE = 'ONE',
  BULK = 'BULK'
}

export const DOWNLOAD_TEMPLATE_CSV_DATA = 'Flowcode Name,Flowcode Scan URL\n,\n,\n,'

export const MAX_FOLDER_NAME_LENGTH = 50
export const MAX_FOLDER_DESCRIPTION_LENGTH = 512
export const MAX_NICKNAME_LENGTH = 128
export const MIN_PASSWORD_LENGTH = 10
export const HOMEPAGE_DEMO_MODAL_URL = 'https://player.vimeo.com/video/683459849'
export const CODE_MANAGEMENT_DEMO_MODAL_URL = 'https://www.youtube.com/embed/Kxa_kt2rxfo'
export const CAREERS_JOIN_FLOWCODE_URL = FLOWCODE_ROOT + '/page/joinflowcode'

export const DOWNLOAD_FLOWCODE_SUCCESS_MESSAGE =
  'Your code was downloaded! Check the downloads section of your browser or computer.'
