import { FC } from 'react'
import { LinkInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import { WIDGETS } from '../widgets/manifest'

export const PageGateComponent: FC<{
  gate?: LinkInfoFragment
}> = ({ gate }) => {
  if (!gate?.type) {
    return <></>
  }
  const GateComponent = WIDGETS[gate.type]?.Flowpage

  return GateComponent ? <GateComponent link={gate} /> : <></>
}
