import { RootPageState } from '@dtx-company/inter-app/src/redux/types'
import { setBannerState } from '@dtx-company/inter-app/src/redux/slices/FlowtagBannerSlice'
import { useDispatch } from 'react-redux'
import { usePageSelector } from '@dtx-company/inter-app/src/hooks/usePageSelector'

export function useConsumerBannerState(): {
  isOpen: boolean
  handleCloseBanner: () => void
  flowtagShopifyLink: string
} {
  const dispatch = useDispatch()

  const bannerState = usePageSelector(
    (state: RootPageState) => state.consumerBannerReducer.bannerEnabled
  )

  const handleCloseBanner = (): void => {
    dispatch(setBannerState(!bannerState))
  }

  // SMS only, load URI from phone or device supporting SMS
  const flowtagShopifyLink = 'https://flowcode.com/p/ZXLy4dGOR?fc=0'

  return { isOpen: bannerState, handleCloseBanner, flowtagShopifyLink }
}
