import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { FC, useEffect, useState } from 'react'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import styled from 'styled-components'
import useScript from '../../utils/useScript'

let map: google.maps.Map<HTMLElement>
let geocoder: google.maps.Geocoder

interface MapProps {
  location: string
  width?: string
  height?: string
}

const addressSections = [
  'street_number',
  'route',
  'locality',
  'administrative_area_level_1',
  'country',
  'postal_code'
]

const Wrapper = styled.div`
  margin: 0 20px;
`
const MapWrapper = styled.div`
  border: 1px solid #dadcdd;
  border-radius: 12px;
  overflow: hidden;
  padding-bottom: 12px;
  margin-bottom: 16px;
`

const StyledLink = styled.a`
  text-decoration: none;
`

const StyledMap = styled.div<{
  $width: string
  $height: string
}>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  margin-bottom: 12px;
`

const Map: FC<MapProps> = ({ location, width, height }) => {
  const [loaded, error] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_CLOUD_API_KEY}&libraries=places`
  )
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')

  const setupMap = (): void => {
    const mapElement = document.getElementById('map')
    if (mapElement) {
      map = new window.google.maps.Map(mapElement, {
        zoom: 14,
        zoomControl: false,
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        draggable: false,
        fullscreenControl: false,
        disableDefaultUI: true
      })
      geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ address: location }, function (results, status) {
        if (status === 'OK') {
          const result = results[0]

          const address: {
            street_number?: string
            route?: string
            locality?: string
            administrative_area_level_1?: string
            country?: string
            postal_code?: string
          } = addressSections.reduce((res: Record<string, string>, section) => {
            const info = result.address_components.find(add => section === add.types[0])
            if (info) {
              res[section] = info.short_name
            }
            return res
          }, {})

          setAddressLine1(`${address?.street_number || ''} ${address?.route || ''}`)
          setAddressLine2(
            `${address?.locality || ''}, ${address?.administrative_area_level_1 || ''} ${
              address?.postal_code || ''
            }`
          )

          map.setCenter(results[0].geometry.location)

          new window.google.maps.Marker({
            map: map,
            position: results[0].geometry.location
          })
        }
      })
    }
  }

  useEffect(
    () => {
      if (loaded && !error) {
        setupMap()
      }
    }, // TODO: resolve this rule of hooks error - issue tracked in https://app.shortcut.com/flowcode/story/41455/resolve-rule-of-hooks-error-that-were-commented-out
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loaded, error]
  )

  return (
    <Wrapper>
      <MapWrapper>
        <StyledMap id="map" $width={width || '500px'} $height={height || '500px'} />
        <Text marginLeft={20} variant={'body/small'} fontWeight="bold">
          {addressLine1}
        </Text>
        <Text marginLeft={20} variant={'body/small'}>
          {addressLine2}
        </Text>
      </MapWrapper>

      <StyledLink
        href={`https://www.google.com/maps/dir//${location?.replace(' ', '+')}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          onClick={() => events.userClickedMapDirectionsButton()}
          sizeVariant="mobileFullWidth"
        >
          Directions
        </Button>
      </StyledLink>
    </Wrapper>
  )
}

export default Map
