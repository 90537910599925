import { PageDisplayState } from '@app/page/src/context/page-display/context'
import { SlotType } from '@app/page/src/containers/settings/settings.types'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'

export const useDisplayGeoInterstitial = (): boolean => {
  const { hasOperation, isAuthenticated } = useAuthState()
  return isAuthenticated && hasOperation('view:interstitial_geolocation')
}

export function checkGeoEnabled(page: PageDisplayState['page']): boolean {
  const slots = page?.slots
  if (slots) {
    return slots.some(
      slot => slot?.slotMeta?.type === SlotType.SETTINGS_GEOLOCATION && slot?.data?.isEnabled
    )
  }
  return false
}
