import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { FooterLink } from '../FooterLink'
import { MobileFooterWrapper } from '../styles'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { footerData } from './footer-data'
import { joinChildren } from '@app/common/src/utils/join-children'

export const TermsAndService: FC = () => (
  <Box justifyContent={'center'} alignItems="center" width="100%" gridArea="trademark">
    <Text fontSize={'10px'} color="primary.white">
      &copy;&nbsp;
      {`${new Date().getFullYear()} the dtx company`}
    </Text>
  </Box>
)

export const WhiteLabelMobileFooter: FC = () => {
  return (
    <MobileFooterWrapper role="contentinfo" padding="4px">
      <Box width="100%" padding="8px" justifyContent="center">
        {joinChildren(
          footerData,
          (link, index) => (
            <FooterLink key={`link-${index}`} {...link}>
              <Text variant="body/small" color="white">
                {link.label}
              </Text>
            </FooterLink>
          ),
          (_, index) => (
            <Spacer key={`spacer-${index}`} mr="8px" />
          )
        )}
      </Box>
      <TermsAndService />
    </MobileFooterWrapper>
  )
}

export const WhiteLabelDesktopFooter: FC = () => {
  return (
    <Box
      role="contentinfo"
      zIndex={9}
      flexDirection="column"
      padding="8px"
      justifyContent="center"
      height="40px"
      display={['none', 'none', 'flex']}
      bg="primary.black"
      gridArea="footer"
    >
      <Box alignItems="center" width="100%" justifyContent="center">
        {footerData.map(link => (
          <FooterLink key={link.href} {...link}>
            <Text variant="body/small" color="white">
              {link.label}
            </Text>
          </FooterLink>
        ))}
      </Box>
      <TermsAndService />
    </Box>
  )
}

export const WhiteLabelFooter: FC = () => {
  return (
    <>
      <WhiteLabelMobileFooter />
      <WhiteLabelDesktopFooter />
    </>
  )
}
