import { SVGProps, memo } from 'react'

function ArrowDownSvg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 11 6" {...rest}>
      <path
        stroke={color || stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10 1L5.5 5 1 1"
      />
    </svg>
  )
}

export const ArrowDown = memo(ArrowDownSvg)
