import { Dispatch, SetStateAction } from 'react'
import {
  GetFlowpageSettingsResponseType,
  PixelDataType,
  PixelType,
  SlotDataType
} from './../../../containers/settings/settings.types'
import { IthacaJwt } from '@dtx-company/ithaca-sdk/src'
import { SWRResponse } from 'swr'
import { UseFormReturn } from 'react-hook-form'

export enum PixelFormFieldNames {
  NAME = 'name',
  PixelType = 'pixelType',
  SRC = 'src'
}

export type UsePixelFormProps = Pick<PixelDataType, 'src' | 'name' | 'pixelType'>

type PixelFormReturn = UseFormReturn<{
  [PixelFormFieldNames.NAME]: string
  [PixelFormFieldNames.PixelType]: PixelType
  [PixelFormFieldNames.SRC]: string
}>

export interface PixelFormState extends PixelFormReturn {
  isSubmitting: boolean
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
}

export interface PixelProps extends SlotDataType {
  handleRemove: (pixelId: string, isNotSaved?: boolean) => void
  mutate: SWRResponse<GetFlowpageSettingsResponseType, Error>['mutate']
  token?: IthacaJwt['token']
  pageId: string
}

export interface PixelState {
  labelText: string
  labelTextId: string
  handleToggle: () => void
  isEnabled: boolean
  handleSubmit: PixelFormState['handleSubmit']
  onSubmit: ({ name, src, pixelType }: UsePixelFormProps) => Promise<void>
  errors: PixelFormState['formState']['errors']
  register: PixelFormState['register']
  setDetailsOpen: (arg0_: boolean | ((arg0: boolean) => boolean)) => void
  detailsOpen: boolean
  values: UsePixelFormProps
  setValue: PixelFormState['setValue']
  isSaveButtonDisabled: boolean
  isSubmitting: boolean
  isDirty: boolean
  typedData: PixelDataType
}
