import * as React from 'react'
import { GeolocationData } from '../../components/PageTracker'
import {
  LinkInfoFragment,
  UnauthPageQuery
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { useMemo, useState } from 'react'
import type { PageDisplayState } from './context'

type UseCreatePageValue = {
  page?: PageType | UnauthPageQuery['unauthPage']
  gate?: LinkInfoFragment
}

export const useCreatePageValue = ({ page, gate }: UseCreatePageValue): PageDisplayState => {
  const [isOwnPage, setIsOwnPage] = useState(false)
  const [gateConditionComplete, setGateConditionComplete] = useState(Boolean(gate) === false)
  const [isGateCRMFormSubmitted, setIsGateCRMFormSubmitted] = useState(false)
  const [unauthTheme, setUnauthTheme] = useState(page?.theme)
  const [pixelPassthrough, setPixelPassthrough] = useState(false)
  const [utmPassthrough, setUtmPassthrough] = useState(false)
  const [geoData, setGeoData] = React.useState<GeolocationData>({
    latitude: undefined,
    longitude: undefined,
    accuracyMeters: undefined
  })

  return useMemo((): PageDisplayState => {
    return {
      pixelPassthrough,
      setPixelPassthrough,
      utmPassthrough,
      setUtmPassthrough,
      unauthTheme,
      gateConditionComplete,
      setGateConditionComplete,
      setUnauthTheme,
      setIsOwnPage,
      isOwnPage,
      isGateCRMFormSubmitted,
      setIsGateCRMFormSubmitted,
      geoData,
      setGeoData,
      page: {
        ...page
      }
    }
  }, [
    page,
    pixelPassthrough,
    utmPassthrough,
    unauthTheme,
    isOwnPage,
    gateConditionComplete,
    isGateCRMFormSubmitted,
    setIsGateCRMFormSubmitted,
    geoData
  ])
}
