import { Routes } from '../../constants/routes'

const dynamicRouteRegex = /(\/)?\[.*?\]/

const dynamicRoutes = Object.values(Routes).filter(route => dynamicRouteRegex.test(route))

/**
 * Checks if a URL is an instance of a dynamic route
 * @param url
 * @returns true if the URL is an instance of a dynamic route, false otherwise
 */
export function isValidDynamicRoute(url: string): boolean {
  const urlSegments = url.split('/')
  for (const route of dynamicRoutes) {
    const routeSegments = route.split('/')

    // If the URL and the route have the same number of segments
    if (urlSegments.length === routeSegments.length) {
      let isMatch = true

      // Compare each segment
      for (let i = 0; i < urlSegments.length; i++) {
        // If this segment is dynamic, skip it
        if (routeSegments[i].startsWith('[') && routeSegments[i].endsWith(']')) continue

        // If this segment does not match, this route does not match the URL
        if (routeSegments[i] !== urlSegments[i]) {
          isMatch = false
          break
        }
      }
      if (isMatch) return true
    }
  }

  return false
}
