import { LayoutProps, PositionProps } from 'styled-system'

export type RadioButtonSizeOptions = 'lg'

export interface RadioButtonVariantsProps {
  lg: LayoutProps & PositionProps
}

export const RADIOBUTTON_SIZE_VARIANTS: RadioButtonVariantsProps = {
  lg: {
    width: 30,
    height: 30
  }
}

export const CHECKED_POS_VARIANTS: RadioButtonVariantsProps = {
  lg: {
    top: '4px',
    left: '4px'
  }
}

export const CHECKED_VARIANTS: RadioButtonVariantsProps = {
  lg: {
    width: 18,
    height: 18
  }
}
