import { AddSnackPayload, Snack } from '../../redux/slices/notifications/notificationsSlice.types'
import {
  UNSUBSCRIBE_HUBSPOT_MUTATION,
  UPDATE_SENDGRID_SUBSCRIPTION_MUTATION
} from '@dtx-company/flow-codegen/src/code/mutations'
import { addSnack, setOpen, setSnacks } from '../../redux/slices/notifications/notificationsSlice'
import { getNotificationsRootUrl } from '@dtx-company/true-common/src/utils/urls/services'
import { gqlFetcher } from '../../services/gqlFetcher'
import { logger } from '@dtx-company/logger'
import type {
  HubspotCommunicationVariablesType,
  SendgridCommunicationVariablesType
} from '@dtx-company/true-common/src/types/notifications'

const VALIDATE_EMAIL_ENDPOINT = getNotificationsRootUrl() + '/verify-email/'

/**
 * Close the notification by setting the open state to false.
 */
export const closeNotification = async (): Promise<void> => {
  try {
    // dynamically import the store to avoid breaking test setup
    // TODO: investigate further
    const { store } = await import('../../redux/store')
    store.dispatch(setOpen(false))
  } catch (error) {
    logger.logError(error, {
      team: 'templates'
    })
  }
}

/**
 * Adds a snack to the redux store, to be displayed in either a
 * {@link Snackbar} or a custom {@link NotificationToast} component.
 * by {@link NotificationsWrapper}
 */
export const sendNotification = async (snack: AddSnackPayload): Promise<void> => {
  try {
    // dynamically import the store to avoid breaking test setup
    // TODO: investigate further
    const { store } = await import('../../redux/store')
    store.dispatch(addSnack(snack))
  } catch (error) {
    logger.logError(error, {
      team: 'templates'
    })
  }
}

export const deleteNotifications = async (): Promise<void> => {
  try {
    // dynamically import the store to avoid breaking test setup
    const { store } = await import('../../redux/store')
    store.dispatch(setSnacks([]))
  } catch (error) {
    logger.logError(error, {
      team: 'templates'
    })
  }
}

/**
 * Display an error toast.
 *
 * @note
 * An "error" toast uses the `error` variant of the {@link Snackbar} component.
 */ export const sendErrorNotification = (
  message: Snack['message'],
  options?: Omit<Snack, 'message'>
): void => {
  sendNotification({
    type: 'error',
    message,
    ...options
  })
}

/**
 * Display a toast.
 *
 * @note
 * A "success" toast uses the `default` variant of the {@link Snackbar} component.
 * Previously we had shown a green toast, but with the new design system we have
 * chosen not to use a custom variant for success toasts.
 */
export const sendSuccessNotification = (
  message: Snack['message'],
  options?: Omit<Snack, 'message'>
): void => {
  sendNotification({
    message,
    ...options
  })
}

export async function validateEmail(
  email: string
): Promise<{ valid_email_address: boolean; suggested_correction?: string }> {
  if (process.env.APP_ENV !== 'production') {
    return {
      valid_email_address: true
    }
  }

  const res = await fetch(VALIDATE_EMAIL_ENDPOINT + email.toLowerCase(), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return await res.json()
}

export const updateSendgridCommunicationPreferences = async (
  variables: SendgridCommunicationVariablesType
): Promise<boolean> => {
  const result: { updateSendgridSubscription: { ok: boolean } } = await gqlFetcher(
    UPDATE_SENDGRID_SUBSCRIPTION_MUTATION,
    variables
  )

  return Boolean(result?.updateSendgridSubscription.ok)
}

export const updateHubspotCommunicationPreferences = async (
  variables: HubspotCommunicationVariablesType
): Promise<boolean> => {
  const result: { unsubscribeHubspot: { ok: boolean } } = await gqlFetcher(
    UNSUBSCRIBE_HUBSPOT_MUTATION,
    variables
  )
  return Boolean(result?.unsubscribeHubspot.ok)
}

// unsubscribeFromMarketingEmails unsubscribes the provided email address from our email marketing channels - currently this is Hubspot only
export const unsubscribeFromMarketingEmails = async (email: string): Promise<Promise<void>> => {
  await updateHubspotCommunicationPreferences({
    email
  })
}
