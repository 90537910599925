/**
 * @deprecated use `theme.breakpoints` from packages/design-system/src/theme/index.ts instead
 */
export enum SIZES {
  mobile = 640,
  tablet = 834,
  desktop = 1024,
  largeDesktop = 1280,
  giantDesktop = 1600
}

const customMediaQuery = (minWidth: number): string => `@media (min-width: ${minWidth}px)`

/**
 * @deprecated use `theme.breakpoints` from packages/design-system/src/theme/index.ts instead
 */
export const media = {
  custom: customMediaQuery,
  tablet: customMediaQuery(SIZES.tablet),
  desktop: customMediaQuery(SIZES.desktop),
  largeDesktop: customMediaQuery(SIZES.largeDesktop),
  giantDesktop: customMediaQuery(SIZES.giantDesktop)
}
