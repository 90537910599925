import { ReactElement } from 'react'
import Link from 'next/link'

export type ConditionalLinkProps = {
  condition: boolean
  href: string
  prefetch?: boolean
  children: ReactElement
}

export const ConditionalLink = ({
  condition,
  href,
  prefetch,
  children
}: ConditionalLinkProps): JSX.Element => {
  return condition ? (
    <Link href={href} prefetch={prefetch} legacyBehavior>
      {children}
    </Link>
  ) : (
    children
  )
}
