import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { isPilotPlanType } from '@app/common/src/utils/isPilotPlanType'
import { shouldRedirectToPausedAccount } from '@app/common/src/utils/shouldRedirectToPausedAccount'
import { useBillingPlanType } from '@app/common/src/hooks/useBillingPlanType/useBillingPlanType'
import { useCallback } from 'react'
import { useIsAccountPaused } from './useIsAccountPaused'
import { useIsBillingAdmin } from './useIsBillingAdmin/useIsBillingAdmin'
import { useIsImpersonating } from '../redux/selectors/currentUserSelectors'
import { useRouter } from 'next/router'

/**
 * Redirects a paused account to Routes.PAUSED_ACCOUNT if the user
 * belongs to the an org that is paused
 *
 * Does not redirect if user is a billing admin trying to go to Routes.ACCOUNT
 * to update view invoices or update their payment info or if an internal admin
 * is impersonating
 */
export const useRedirectToPausedAccountPage = (): (() => void) => {
  const router = useRouter()
  const isBillingAdmin = useIsBillingAdmin()
  const isAccountPaused = useIsAccountPaused()
  const isImpersonating = useIsImpersonating()
  const billingPlanType = useBillingPlanType()
  const isPilot = billingPlanType && isPilotPlanType(billingPlanType)

  const doNotRedirect = !shouldRedirectToPausedAccount({
    url: router.pathname,
    isBillingAdmin: isBillingAdmin || false,
    isPilot: isPilot || false,
    isImpersonating
  })

  const redirectToPausedAccountPage = useCallback(() => {
    if (doNotRedirect) return
    if (isAccountPaused)
      router.push({
        pathname: Routes.PAUSED_ACCOUNT
      })
  }, [router, doNotRedirect, isAccountPaused])
  return redirectToPausedAccountPage
}
