export const addUser = '/icons/add-user.svg'
export const arrowRightDisabled = '/icons/arrow-right-disabled.svg'
export const arrowRightBlack = '/icons/arrow-right-black.svg'
export const arrowRightFlowBlue = '/icons/arrow-right-flowblue.svg'
export const arrowRightWhite = '/icons/arrow-right-white.svg'
export const arrowLeftBlue = '/icons/arrow-left-blue.svg'
export const arrowUp = '/icons/arrow-up.svg'
export const arrowUpBlack = '/icons/arrow-up-black.svg'
export const arrowDown = '/icons/arrow-down.svg'
export const arrowDownAlt = '/icons/arrow-down-white-alt.svg'
export const avatar = '/icons/avatar.svg'
export const aspectRatio = '/icons/aspect_ratio.svg'
export const blueArrow = '/icons/blue-arrow.svg'
export const bioPlaceholder = '/icons/placeholders/bio-placeholder.svg'
export const bolt = '/icons/bolt.svg'
export const book = '/icons/book.svg'
export const cancelCheckmark = '/icons/cancel-checkmark.svg'
export const chart = '/icons/myflowcodes/chart.svg'
export const checkmark = '/icons/checkmark.svg'
export const checkmarkBlack = '/icons/checkmark-black.svg'
export const checkmarkBlue = '/icons/checkmark-blue.svg'
export const checkmarkBlueLight = '/static/icons/checkmark-light-blue.svg'
export const checkmarkBlueLarger = '/icons/checkmark-blue-larger.svg'
export const checkmarkGreen = '/icons/checkmark-green.svg'
export const checkmarkGray = '/icons/checkmark-gray.svg'
export const checkmarkWhite = '/icons/checkmark-white.svg'
export const checkmarkOutlineGreen = '/icons/actions/checkmark-green.svg'
export const chevronBlack = '/icons/chevron-black.svg'
export const chevronLeftGrey = '/icons/navigation/chevron-left-grey.svg'
export const chevronLeftWhite = '/icons/chevron-left-white.svg'
export const chevronRight = '/icons/navigation/chevron-right.svg'
export const chevronRightBlueThicker = '/icons/navigation/chevron-right-blue-thicker.svg'
export const chevronLeftBlueThicker = '/icons/navigation/chevron-left-blue-thicker.svg'
export const circleBlack = '/icons/black-circle.svg'
export const circleWhite = '/icons/white-circle.svg'
export const circularNext = '/icons/circular-next.svg'
export const circularPrev = '/icons/circular-prev.svg'
export const clock = '/icons/clock.svg'
export const closeBlack = '/static/icons/closeBlack.svg'
export const closeWhite = '/icons/close-icon-white.svg'
export const clockGrey = '/icons/myflowcodes/clock-grey.svg'
export const copyIcon = '/icons/copy-icon.svg'
export const data = '/icons/data.svg'
export const defaultNavAvatar = '/icons/default_nav_avatar.png'
export const deleteIcon = '/icons/delete.svg'
export const dividerLine = '/icons/divider-line.svg'
export const download = '/icons/download.svg'
export const downloadBlack = '/icons/download-black.svg'
export const downloadBlue = '/icons/download-blue.svg'
export const droplet = '/icons/droplet.svg'
export const editCircle = '/icons/edit-circle.svg'
export const educationBlue = '/icons/education-outline.svg'
export const email = '/icons/myflowcodes/email.svg'
export const facebookWhite = '/icons/facebook_white.svg'
export const flowcodeDesign = '/icons/flowcode-design.svg'
export const flowcodeIconActive = '/icons/flowcode-icon-active.svg'
export const flowcodeIconWhite = '/icons/flowcode-icon-white.svg'
export const flowcodeSymbol = '/icons/myflowcodes/flowcode-symbol.svg'
export const flowcodeTvBlack = '/icons/flowcode-tv-black.svg'
export const flowcodeTvWhite = '/icons/flowcode-tv-white.svg'
export const flowpageBlack = '/icons/flowpage-icon-black.svg'
export const flowpageWhite = '/icons/flowpage-icon-white.svg'
export const flowpageBlue = '/icons/flowpage-blue.svg'
export const fileType = '/icons/file_type.svg'
export const imageFilledBlack = '/images/landing-page-destinations/image-filled.svg'
export const informationBlack = '/icons/information-black.svg'
export const informationGrey = '/icons/information-grey.svg'
export const informationWhite = '/icons/information-white.svg'
export const myCodesSymbolBlack = '/icons/my-codes-black.svg'
export const myCodesSymbolBlackFilled = 'icons/my-codes-black-filled.svg'
export const myCodesSymbolWhite = '/icons/my-codes.svg'
export const folder = '/icons/myflowcodes/folder.svg'
export const folder1 = '/icons/folder-1.svg'
export const folderWhite = '/icons/myflowcodes/folder-white.svg'
export const folderGrey = '/icons/myflowcodes/folder-grey.svg'
export const grid = '/icons/myflowcodes/grid.svg'
export const handshakeBlue = '/icons/handshake-blue.svg'
export const iphone = '/icons/iphone.svg'
export const landscape = '/icons/landscape.svg'
export const launch = '/icons/launch.svg'
export const lightningOutline = '/icons/lightning-outline.svg'
export const link = '/icons/myflowcodes/link.svg'
export const linkPlaceholder = '/icons/placeholders/link-placeholder.svg'
export const list = '/icons/myflowcodes/list.svg'
export const lock = '/icons/lock.svg'
export const lockOutlineGrey = '/icons/lock-outline-grey.svg'
export const move = '/icons/myflowcodes/move.svg'
export const moveIcon = '/icons/move-icon.svg'
export const partyIcon = '/icons/party-icon.svg'
export const paperclip = '/icons/actions/paperclip.svg'
export const pdf = '/icons/pdf.svg'
export const pdfPlaceholder = '/icons/placeholders/pdf-placeholder.svg'
export const plus = '/icons/plus.svg'
export const questionMarkBlue = '/icons/question-mark-blue.svg'
export const questionMarkFlowBlue = '/icons/question-mark-flow-blue.svg'
export const questionMarkGray = '/icons/question-mark-gray.svg'
export const questionMarkBlack = '/icons/question-mark-black.svg'
export const resetArrow = '/icons/reset-arrow.svg'
export const scan = '/icons/scan.svg'
export const search = '/icons/myflowcodes/search.svg'
export const smile = '/icons/smile.svg'
export const threeDots = '/icons/myflowcodes/three-dots.svg'
export const threeDotsBlue = '/icons/navigation/three-vertical-dots-blue.svg'
export const times = '/icons/x.svg'
export const trash = '/icons/myflowcodes/trash.svg'
export const trashCanGrey = '/icons/myflowcodes/trash-can-grey.svg'
export const trashCanBold = '/icons/trash-bold.svg'
export const trashCanRed = '/icons/myflowcodes/trash-can-red.svg'
export const trashCanBlack = '/icons/myflowcodes/trash-can-black.svg'
export const twitterWhite = '/icons/social-media/twitter.svg'
export const videoFilledBlack = '/images/landing-page-destinations/video-filled.svg'
export const web = '/icons/myflowcodes/web.svg'
export const moveArrow = '/icons/myflowcodes/move-arrow.svg'
export const moveFolderBlue = '/icons/myflowcodes/move-folder-blue.svg'
export const moveFolderGrey = '/icons/myflowcodes/move-folder-grey.svg'
export const downloadCircle = '/icons/myflowcodes/download-circle.svg'
export const paintBrush = '/icons/myflowcodes/paintbrush.svg'
export const pencilGrey = '/icons/everyday/pencil_grey.svg'
export const spinner = '/icons/spinner.svg'
export const flowcodeCircleLogoBlack = '/icons/flowcode-circle-logo-black.svg'
export const flowcodeLogoRoundLargeBlack = '/logos/flowcode/round/large-black.svg'
export const proRocketWhite = '/icons/homepage/pro-rocket-white.svg'
export const flowcodeLogoText = '/logos/flowcode-text.svg'
export const barGraph = '/icons/bar-graph.svg'
export const paintRoller = '/icons/paint-roller.svg'
export const headphones = '/icons/headphones.svg'
export const bulkCodeCreation = '/icons/bulk-code-creation.svg'
export const megaphone = '/icons/everyday/megaphone-blue.svg'
export const homeOutlineBlue = '/icons/navigation/home-outline-blue.svg'
export const packageBlue = '/icons/everyday/package-blue.svg'
export const forkAndKnifeBlue = '/icons/everyday/fork-and-knife-blue.svg'
export const marketplaceBlue = '/icons/everyday/marketplace-blue.svg'
export const profile2Blue = '/icons/navigation/profile-2-blue.svg'
export const playBlue = '/icons/everyday/play-blue.svg'
export const bookOpenBlue = '/icons/everyday/book-open-blue.svg'
export const flowcodeTvText = '/logos/flowcode-for-tv-text.svg'
export const lockIconWhite = '/icons/actions/lock-white.svg'
export const playStoreAppStore = '/icons/social-media/app-store-play-store-color.svg'
export const appStore = '/icons/social-media/app-store-color.svg'
export const playStore = '/icons/social-media/play-store-color.svg'
export const appStoreBadgeDark = '/icons/social-media/app-store-badge-dark.svg'
export const appStoreBadgeLight = '/icons/social-media/app-store-badge-light.svg'
export const playStoreBadgeDark = '/icons/social-media/play-store-badge-dark.svg'
export const playStoreBadgeLight = '/icons/social-media/play-store-badge-light.svg'
export const flowcodeTextLogo = '/logos/flowcode_black_without_circle.svg'
export const instagramAlt = '/icons/social-media/instagram-filled.svg'
export const uploadBold = '/icons/upload-bold.svg'
export const linkBlueOutline = '/icons/social-media/link-blue-outline.svg'
export const flowpageIconFilled = '/icons/social-media/flowpage-filled.svg'
export const mailchimpColor = '/icons/social-media/mailchimp-color.svg'
export const hubspotColor = '/icons/social-media/hubspot-color.svg'
export const zapierColor = '/icons/social-media/zapier-color.svg'
export const fileUploadColor = '/icons/social-media/file-upload-color.svg'
export const klaviyoColor = '/icons/social-media/klaviyo-color.svg'
export const salesforceColor = '/icons/social-media/salesforce-color.svg'
export const openEye = '/static/icons/open_eye.svg'
export const openEyeSlash = '/static/icons/open_eye_slash.svg'
export const sparkle = '/icons/sparkle-icon.svg'

// BRANDS
export const amexFilledBlack = '/icons/brands-using-flowcode/amex-filled-black.svg'
export const balaFilledBlack = '/icons/brands-using-flowcode/bala-filled-black.svg'
export const beautyCounterFilled = '/icons/brands-using-flowcode/beautycounter-filled.svg'
export const bentleyFilled = '/icons/brands-using-flowcode/bentley-filled.svg'
export const bentleyBlack = '/icons/brands-using-flowcode/bentley-black.svg'
export const bodemillerFilled = '/icons/brands-using-flowcode/bodemiller-filled.svg'
export const bodyByJakeFilled = '/icons/brands-using-flowcode/body-by-jake-filled.svg'
export const chipotleFilledBlack = '/icons/brands-using-flowcode/chipotle-filled-black.svg'
export const credaiFilled = '/icons/brands-using-flowcode/credai-filled.svg'
export const crossonFilled = '/icons/brands-using-flowcode/crosson-filled.svg'
export const erewhonBlack = '/icons/brands-using-flowcode/erewhon-black.svg'
export const feedingAmericaFilled = '/icons/brands-using-flowcode/feeding-america-filled.svg'
export const feedingAmericaFilledBlack =
  '/icons/brands-using-flowcode/feedingamerica-filled-black.svg'
export const gapFilledBlack = '/icons/brands-using-flowcode/gap-filled-black.svg'
export const givzFilled = '/icons/brands-using-flowcode/givz-filled.svg'
export const gmaFilled = '/icons/brands-using-flowcode/gma-filled.svg'
export const godaddyFilled = '/icons/brands-using-flowcode/godaddy-filled.svg'
export const godaddyFilledBlack = '/icons/brands-using-flowcode/godaddy-filled-black.svg'
export const goopFilled = '/icons/brands-using-flowcode/goop-filled.svg'
export const hearstFilledBlack = '/icons/brands-using-flowcode/hearst-filled-black.svg'
export const hokaOneOneFilledBlack = '/icons/brands-using-flowcode/hokaoneone-filled-black.svg'
export const homeDepotFilledBlack = '/icons/brands-using-flowcode/homedepot-filled-black.svg'
export const houseOfLRCFilled = '/icons/brands-using-flowcode/house-of-lrc-filled.svg'
export const iclAcademyFilled = '/icons/brands-using-flowcode/icl-academy-filled.svg'
export const jloBeautyFilled = '/icons/brands-using-flowcode/jlo-beauty-filled.svg'
export const jloBeautyFilledBlack = '/icons/brands-using-flowcode/jlo-beauty-filled-black.svg'
export const jordanFilled = '/icons/brands-using-flowcode/jordan-filled.svg'
export const jordanRealTalkFilled = '/icons/brands-using-flowcode/jordan-real-talk-filled.svg'
export const lululemonFilledBlack = '/icons/brands-using-flowcode/lululemon-filled-black.svg'
export const newYorkPostFilledBlack = '/icons/brands-using-flowcode/newyorkpost-filled-black.svg'
export const recessBlack = '/icons/brands-using-flowcode/recess-black.svg'
export const sinclairBroadcastGroupFilled =
  '/icons/brands-using-flowcode/sinclair-broadcast-group-filled.svg'
export const sinclairMediaNetworkFilled =
  '/icons/brands-using-flowcode/sinclair-media-network-filled.svg'
export const skeoFilled = '/icons/brands-using-flowcode/skeo-filled.svg'
export const snobahnFilled = '/icons/brands-using-flowcode/snobahn-filled.svg'
export const sofiFilled = '/icons/brands-using-flowcode/sofi-filled.svg'
export const sofiFilledBlack = '/icons/brands-using-flowcode/sofi-filled-black.svg'
export const sothebysFilled = '/icons/brands-using-flowcode/sothebys-filled.svg'
export const sothebysFilledBlack = '/icons/brands-using-flowcode/sothebys-filled-black.svg'
export const stockXFilled = '/icons/brands-using-flowcode/stock-x-filled.svg'
export const stockXFilledBlack = '/icons/brands-using-flowcode/stock-x-filled-black.svg'
export const supergoopBlack = '/icons/brands-using-flowcode/supergoop-black.svg'
export const theMoveFilled = '/icons/brands-using-flowcode/the-move-filled.svg'
export const universityofMichiganBlack = '/icons/brands-using-flowcode/uofm-black.svg'
export const usaOlympicsBlack = '/icons/brands-using-flowcode/usaolympics-black.svg'
// PRO
export const proFunnelCTAStepCode = '/images/pro-upgrade-modal/pro-funnel-cta-step-code.png'
export const ruleBasedScanInfographic = '/images/pro-upgrade-modal/rule-based-scan-infographic.svg'
export const gradientBackgroundBlue = '/graphics/gradient-background-blue.svg'
export const gradientBackgroundPink = '/graphics/gradient-background-pink.svg'
export const gradientBackgroundYellow = '/graphics/gradient-background-yellow.svg'
export const flowpagesExample = '/graphics/flowpage-examples.png'
export const proUpgradeRocketIcon = '/icons/product/pro-upgrade-rocket.svg'
export const enterpriseUpgradeRocketIcon = '/icons/product/enterprise-upgrade-rocket.svg'
export const rocketFilledBlue = '/icons/everyday/rocket-filled-blue.svg'
export const rocketFilledPurple = '/icons/everyday/rocket-filled-purple.svg'
export const googleFontsTileMobile = '/graphics/google-fonts-upgrade.png'
//GRAPHICS
export const jloCodeCombo = '/graphics/jlo-code-combo.png'
export const feedingAmericaCodeCombo = '/graphics/fa-code-combo.png'
export const gmaCodeCombo = '/graphics/gma-code-combo.png'
export const presetCollectCode = '/videos/Preset/PresetScanCode.mp4'
export const presetCollectCodePoster = '/videos/Preset/PresetCollectCodePoster.mp4'
// ANALYTICS
export const arrowSuccess = '/icons/arrow-up-success.svg'
export const arrowWarning = '/icons/arrow-down-warning.svg'
export const dateBlack = '/icons/date-black.svg'
export const dateGrey = '/icons/date-grey.svg'
// LockModal
export const LockModalProGraphicBG = '/graphics/lockModal/pro-graphic-background.svg'
export const LockModalFCProHexColors = '/graphics/lockModal/fc-pro-hex-colors.png'
export const LockModalFPProHexColors = '/graphics/lockModal/fp-pro-hex-colors.png'
export const LockModalFPProFonts = '/graphics/lockModal/fp-pro-fonts.svg'
export const LockModalFCProScanDestination = '/graphics/lockModal/fc-pro-scan-destinations.svg'
export const LockModalFCProPlusWhitelabel = '/graphics/lockModal/fc-pro-plus-whitelabel.svg'
export const LockModalFPProMailchimpCRM = '/graphics/lockModal/fp-pro-mailchimp-crm.svg'
export const LockModalFPProPlusZapierCRM = '/graphics/lockModal/fp-pro-plus-zapier-crm.svg'
export const LockModalFPProPlusWhitelabel = '/graphics/lockModal/fp-pro-plus-whitelabel-lg.svg'
export const LockModalFCProCodeLimit = '/graphics/lockModal/fc-pro-code-limit.svg'
export const LockModalFPProFontStyles = '/graphics/lockModal/fp-pro-font-styles.png'
export const LockModalFPProPlusLinkLevelStyling =
  '/graphics/lockModal/fp-pro-plus-link-level-styling.png'
export const LockModalCoreProPlusDomains = '/graphics/lockModal/core-pro-plus-domains.png'
export const LockModalFPProPlusPageTemplates = '/graphics/lockModal/fp-pro-plus-page-templates.png'
export const LockModalFCCodeLimit = '/graphics/lockModal/fc-pro-code-limit2.svg'
export const ProPlusFlowteams = '/graphics/lockModal/pro-plus-flowteams.png'

// Upgrade - Downgrade
export const NewFeature = '/icons/new-feature.svg'
export const OldFeature = '/icons/old-feature.svg'
// DemoModal
export const DemoModalCreativeVideoServices = '/graphics/demoModal/creative-video-services.png'
export const DemoModalUnlimitedPlatformSeats = '/graphics/demoModal/unlimited-platform-seats.png'
export const DemoModalGeolocation = '/graphics/demoModal/geolocation.png'
export const DemoModalPermissionSharing = '/graphics/demoModal/permission-sharing.png'
export const DemoModalProFlexFlowteams = '/graphics/demoModal/pro-flex-flowteams.png'
export const DemoModalHubspotCRM = '/graphics/demoModal/hubspot-crm.png'
export const DemoModalCodeLimit = '/graphics/demoModal/code-limit.png'

//FlowTeams
export const GetStartedFlowteams = '/graphics/flowteams/get-started-flowteams.png'
export const FlowteamsAssetSharing = '/graphics/flowteams/flowteams-asset-sharing.png'

//PROMO
export const CyberWeek40 = '/graphics/promo/cyberweek40.svg'

//Video
export const VideoToolPlaceholderGraphic = '/graphics/video-tool-placeholder.png'
