import { TrialConfig } from './TrialConfig'
import { isValid, parseISO } from 'date-fns'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useMemo } from 'react'

function parseDate(iso?: string): Date | undefined {
  if (!iso) return undefined
  const date = parseISO(iso)
  if (!isValid(date)) return undefined
  return date
}

/**
 * Provides configuration about the current user's org's trial.
 *
 * Any plan can be in a trial, this should not be specific to the PILOT plan
 * or any other specific plan or user data.
 *
 * @see useIsBillingAdmin
 * @see useBillingPlanType
 */
export function useTrialConfig(): TrialConfig | undefined {
  const authState = useAuthState()
  return useMemo(() => {
    if (!authState.isAuthChecked) return undefined
    return {
      inTrial: authState.org?.status === 'TRIALING',
      endAt: parseDate(authState.org?.trialEndAt)
    }
  }, [authState.isAuthChecked, authState.org?.status, authState.org?.trialEndAt])
}
