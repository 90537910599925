import {
  BackgroundProps,
  BorderProps,
  ColorProps,
  LayoutProps,
  SpaceProps,
  TypographyProps
} from 'styled-system'
import { BoxComponentProps } from '../../../src'
import {
  CSSProperties,
  ChangeEvent,
  FocusEvent,
  HTMLProps,
  KeyboardEvent,
  ReactElement
} from 'react'
import { TextStyleProps } from './text.types'

export enum InputMode {
  NONE = 'none',
  TEXT = 'text',
  DECIMAL = 'decimal',
  NUMERIC = 'numeric',
  TEL = 'tel',
  SEARCH = 'search',
  EMAIL = 'email',
  URL = 'url'
}

export interface InputComponentProps {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void
  type: string
  label?: string
  value?: string | number
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  autoFocus?: boolean
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  placeholder?: string
  helperText?: string
  helperTextProps?: TypographyProps & LayoutProps & ColorProps & SpaceProps
  disabled?: boolean
  startAdornment?: ReactElement
  startAdornmentWrapperProps?: BoxComponentProps
  multiline?: boolean
  endAdornment?: ReactElement
  error?: boolean
  labelProps?: TextStyleProps
  id?: string
  autoComplete?: string
  name?: string
  inputProps?: TypographyProps &
    BorderProps &
    SpaceProps &
    HTMLProps<HTMLInputElement> & {
      'data-heap-id'?: string
      'data-testid'?: string
      focusColor?: string
    }
  defaultValue?: string | number | string[] | undefined
  onClear?: () => void
  controlled?: boolean
  enableResize?: boolean
  resizeDirection?: CSSProperties['resize']
  focusBorderColor?: string
  ariaLabelledBy?: string
}
export interface InputProps
  extends ColorProps,
    TypographyProps,
    SpaceProps,
    BorderProps,
    LayoutProps,
    InputComponentProps {
  inputMode?: InputMode
}

export interface InputBaseProps
  extends HTMLProps<HTMLInputElement>,
    BorderProps,
    TypographyProps,
    SpaceProps,
    BackgroundProps {
  disableResize?: boolean
  resizeDirection?: CSSProperties['resize']
  focusBorderColor?: string
  focusColor?: string
}
