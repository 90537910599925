import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ConsumerBannerProps } from './ConsumerBanner.types'
import { FC } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { MarketingPageRoutes } from '@dtx-company/true-common/src/constants/routes'
import { PrimaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { useConsumerBannerState } from './ConsumerBanner.hooks'
import { useRouter } from 'next/router'
import styled from 'styled-components'

// zIndex higher than footer's
const StyledBox = styled(Box)`
  z-index: 10001;
`

export const FlowpageConsumerBanner: FC<ConsumerBannerProps> = ({ pageId, isFlowtagBanner }) => {
  const router = useRouter()
  const { isOpen, handleCloseBanner, flowtagShopifyLink } = useConsumerBannerState()
  return (
    <>
      {isOpen && (
        <StyledBox
          backgroundColor="primary.black"
          padding="21px 19px 22px 34px"
          position="fixed"
          bottom={0}
          right={0}
          left={0}
        >
          <Box width="100%" alignItems="baseline" justifyContent="center">
            {isFlowtagBanner ? (
              <PrimaryButton
                label="Get your own Flowtag"
                as="a"
                href={flowtagShopifyLink}
                width={['100%', '100%', '290px']}
                data-testid="get-flowtag"
              />
            ) : (
              <PrimaryButton
                label="Get your own Flowpage"
                width={['100%', '100%', '290px']}
                onClick={() => {
                  router.push({
                    pathname: MarketingPageRoutes.PAGE,
                    query: {
                      utm_source: 'FPbanner',
                      utm_medium: 'flowpage',
                      utm_campaign: pageId
                    }
                  })
                }}
                data-testid="get-flowpage"
              />
            )}
            <Spacer mr="10px" />
            <Icon src="/icons/GreyClose.svg" opacity={0.5} button onClick={handleCloseBanner} />
          </Box>
        </StyledBox>
      )}
    </>
  )
}
