import { ModalHookReturn, useModal } from '@dtx-company/inter-app/src/redux/slices/utils/modal'
import { ModalTypes } from '@dtx-company/inter-app/src/redux/slices/modal'

export type ResetCustomizationOptionsModalType = ModalHookReturn & {
  props?: {
    themeId?: string
  }
}

export const useResetCustomizationModal = (): ResetCustomizationOptionsModalType => {
  return useModal(ModalTypes.RESET_CUSTOMIZATION_OPTIONS)
}
