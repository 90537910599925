import * as icons from '../../assets/icons'
import { DELETE_SLOT } from '@dtx-company/flow-codegen/src/page/mutations'
import { FC, useCallback, useEffect, useState } from 'react'
import { GetFlowpageSettingsResponseType, PixelType, SlotDataType } from './settings.types'
import { LinkType, UnuathPageQuerySlot } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { Maybe } from '@dtx-company/flow-codegen/src/page/generated.types'
import { Pixel } from '../../components/profile/Pixel/pixel'
import { PixelDeleteConfirmationModal } from '../../components/profile/PixelModal'
import { PrimaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { SWRResponse } from 'swr'
import { getValidatedActionDataOrThrow } from '../../components/widgets/typeUtils'
import { gqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import {
  sendErrorNotification,
  sendSuccessNotification
} from '@dtx-company/inter-app/src/utils/notifications'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { usePageDisplayContext } from '../../context/page-display'
import Head from 'next/head'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

export interface PixelContainerProps {
  pixelData: SlotDataType[]
  mutate: SWRResponse<GetFlowpageSettingsResponseType, Error>['mutate']
  pageId: string
}

export const PixelContainer = ({ mutate, pixelData, pageId }: PixelContainerProps): JSX.Element => {
  const { token } = useAuthState()
  const [pixels, setPixels] = useState<SlotDataType[]>([])
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const [pixelIdToDelete, setpixelIdToDelete] = useState('')

  useEffect(() => {
    setPixels(pixelData)
  }, [pixelData])

  const handleAddPixel = (): void => {
    const defaultPixelState: SlotDataType = {
      id: String(pixels.length + 1),
      data: {
        name: '',
        pixelType: PixelType.iframe,
        isEnabled: true,
        src: '',
        snippet: '',
        isNotSaved: true
      }
    }
    setPixels([...pixels, defaultPixelState])
  }
  const handleModalClose = (): void => {
    events.userDeclinedPixelRemoval()
    setIsDeleteConfirmationModalOpen(false)
  }

  const handleModalDelete = async (): Promise<void> => {
    const sendPixelDeleteRequest = async (
      pageId: string,
      slotId: string
    ): Promise<{ deleteSlot: boolean }> => {
      events.userConfirmedPixelRemoval()
      return gqlFetcher(DELETE_SLOT, { slotId, pageId }, true, token)
    }
    try {
      mutate({ slots: [...pixels.filter(px => px.id != pixelIdToDelete)] }, false)
      await sendPixelDeleteRequest(pageId, pixelIdToDelete)
      mutate()
      sendSuccessNotification('Successfully deleted your pixel')
    } catch (err) {
      console.error(err)
      sendErrorNotification('Could not delete your pixel')
    }
    setIsDeleteConfirmationModalOpen(false)
  }

  const handlePixelRemove = useCallback(
    async (pixelId: string, isNotSaved?: boolean): Promise<void> => {
      if (isNotSaved) {
        setPixels(pixels => pixels.filter(px => px.id != pixelId))
        return
      }
      events.userTriggeredRemovePixelModal()
      setpixelIdToDelete(pixelId)
      setIsDeleteConfirmationModalOpen(true)
    },
    []
  )

  return (
    <>
      {pixels.map((pixel, key) => {
        return (
          <Pixel
            key={key}
            handleRemove={handlePixelRemove}
            token={token}
            pageId={pageId}
            mutate={mutate}
            {...pixel}
          />
        )
      })}
      <PrimaryButton
        onClick={handleAddPixel}
        icon={icons.plusWhite}
        sizeVariant="mobileFullWidth"
        label="Add custom Pixel"
        width={['100%', 'auto', 'auto', 'auto']}
      />
      <PixelDeleteConfirmationModal
        open={isDeleteConfirmationModalOpen}
        handleClose={handleModalClose}
        handleDelete={handleModalDelete}
      />
    </>
  )
}
export const FBPixel: FC<{ link: Maybe<LinkType> }> = ({ link }) => {
  const { setUtmPassthrough } = usePageDisplayContext()
  const actionData = getValidatedActionDataOrThrow<'fbPixel'>(link?.actionData, 'pixelId')
  useEffect(() => {
    if (actionData.utmPassthrough) {
      setUtmPassthrough(actionData.utmPassthrough)
    }
  }, [actionData.utmPassthrough, setUtmPassthrough])

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${encodeURIComponent(actionData.pixelId)}');
            fbq('track', 'PageView');
          `
        }}
      />
    </Head>
  )
}

export const PixelHomepageContainer = ({
  pixels,
  fbPixels
}: {
  pixels: UnuathPageQuerySlot[]
  fbPixels: Maybe<LinkType>[]
}): JSX.Element => {
  const createPixel = (key: number, src: string, pixelType?: string): JSX.Element => {
    switch (pixelType) {
      case 'iframe':
        return <iframe key={key} src={src} style={{ display: 'none' }} />
      case 'image':
      default:
        return <img key={key} src={src} style={{ display: 'none' }} />
    }
  }

  return (
    <>
      {pixels.map((value, key) => {
        if (value) return createPixel(key, value.data.src, value.data.pixelType)
      })}
      {fbPixels.map(fbPixel => (
        <FBPixel key={fbPixel?.id} link={fbPixel} />
      ))}
    </>
  )
}
