export const joinChildren = <T>(
  items: T[],
  render: (item: T, index: number) => React.ReactNode,
  renderSeparator: (item: T, index: number) => React.ReactNode
): React.ReactNode[] => {
  const acc: React.ReactNode[] = []
  return items.reduce((result, item, index) => {
    if (index < items.length - 1) {
      return result.concat([render(item, index), renderSeparator(item, index)])
    }

    return result.concat(render(item, index))
  }, acc)
}
