import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { layout } from 'styled-system'
import styled from 'styled-components'

export const TABLET_FOOTER_BREAKPOINT = 655
export const DESKTOP_FOOTER_BREAKPOINT = 1046
export const DESKTOP_FOOTER_HEIGHT = 260

export const GlobalLayout = styled(Box)<{
  marginBottom?: number
  navHeight: number
  disableTopNav: boolean
  disableSideNav?: boolean
  showOverflow?: boolean
}>`
  //Update right-side content w/ sidebar when we want to enable that
  display: grid;
  position: relative;
  grid-template-areas:
    'banner banner'
    'header header'
    'sidebar content'
    'footer footer';
  grid-template-rows: 0 ${({ disableTopNav, navHeight }) =>
      disableTopNav ? '0px' : `${navHeight}px`} 1fr auto;
  grid-template-columns: auto 1fr;
  // if marginbottom exist, min-height must be less then 100vh to avoid vertical scroll
  min-height: ${({ marginBottom }) => (marginBottom ? `calc(100vh - ${marginBottom}px)` : '100vh')};

  ${layout}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`} ${({
    showOverflow
  }) =>
    showOverflow ? '' : `overflow-x: hidden;`} @media(min-width: ${TABLET_FOOTER_BREAKPOINT}px) {
    grid-template-rows: auto ${({ disableTopNav, navHeight }) =>
        disableTopNav ? `0 1fr auto` : `${navHeight}px 1fr auto`};
  }

  @media (min-width: 832px) {
    grid-template-rows: auto ${({ disableTopNav, navHeight }) =>
        disableTopNav ? '0 1fr auto' : `${navHeight}px 1fr auto`};
    ${({ disableSideNav, showOverflow }) =>
      disableSideNav && !showOverflow && `overflow-y: hidden;`}
  }
  @media (min-width: ${DESKTOP_FOOTER_BREAKPOINT}px) {
    height: auto;
    grid-template-rows: auto ${({ disableTopNav, navHeight }) =>
        disableTopNav ? `0 1fr auto` : `${navHeight}px 1fr auto`};
    ${({ disableSideNav, showOverflow }) =>
      disableSideNav && !showOverflow && ` overflow-y: hidden;`}
  }
`
