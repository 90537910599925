import {
  AlertButtonPositionVariantProps,
  AlertButtonSizeVariantProps,
  AlertPositionVariantProps,
  AlertShapeVariantProps,
  AlertSizeVariantProps,
  AlertTypeVariantProps
} from '..'

export const ALERT_TYPE_VARIANTS: AlertTypeVariantProps = {
  error: {
    backgroundColor: 'status.errorDark',
    color: 'primary.white'
  },
  errorLight: {
    backgroundColor: 'status.errorLight',
    color: 'primary.black'
  },
  primary: {
    backgroundColor: 'primary.flowBlue',
    color: 'primary.white'
  },
  primaryBlack: {
    backgroundColor: 'primary.black',
    color: 'primary.white'
  },
  secondary: {
    backgroundColor: 'secondary.backgroundDark',
    color: 'primary.black'
  },
  success: {
    backgroundColor: 'masterWeb.green4',
    color: 'primary.white'
  },
  tertiary: {
    backgroundColor: 'primary.black',
    color: 'primary.white'
  }
}

export const ALERT_SHAPE_VARIANTS: AlertShapeVariantProps = {
  curved: {
    borderRadius: '8px',
    padding: '20px 22px'
  },
  sharp: {
    borderRadius: 'none',
    padding: '14px 16px'
  }
}

export const ALERT_POSITION_VARIANTS: AlertPositionVariantProps = {
  topLeft: {
    top: '20px',
    left: 0
  },
  topCenter: {
    top: '20px',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  topRight: {
    top: '20px',
    right: 0
  },
  center: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  bottomLeft: {
    bottom: '20px',
    left: 0
  },
  bottomCenter: {
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  bottomRight: {
    bottom: '20px',
    right: 0
  }
}

export const ALERT_SIZE_VARIANTS: AlertSizeVariantProps = {
  lg: {
    width: '520px',
    fontSize: '15px',
    lineHeight: 1.67
  },
  md: {
    width: '375px',
    fontSize: '13px',
    lineHeight: 1.77
  },
  sm: {
    width: '335px',
    fontSize: '13px',
    lineHeight: 1.62
  }
}

// TODO:  Note: Button component doesn't scale with the label.
// If the Button component style is changed from height to padding then enable the padding here and remove height.
export const ALERT_BUTTON_SIZE_VARIANTS: AlertButtonSizeVariantProps = {
  lg: {
    // padding: '10px 16px',
    height: '35px'
  },
  md: {
    // padding: '9px 13px',
    height: '31px'
  },
  sm: {
    // padding: '8px 12px',
    height: '29px'
  }
}

export const ALERT_BUTTON_POSITION_VARIANTS: AlertButtonPositionVariantProps = {
  inline: {
    justifyContent: 'normal'
  },
  newlineLeft: {
    justifyContent: 'normal'
  },
  newlineCenter: {
    justifyContent: 'center'
  },
  newlineRight: {
    justifyContent: 'flex-end'
  }
}
