import { FcBuilder } from './fc_builder.types'
import {
  FcColor,
  FcColorBlack,
  FcColorGradient,
  FcColorTranslation,
  FcColorWhite,
  FcErrorCorrectionLevel,
  FcGradient,
  FcPercentageSize,
  FcPosition,
  FcPositionProperty,
  FcShape,
  FcSize,
  FcText
} from './fc_common.types'
import { FcRenderer } from './fc_renderer.types'
import { FcSvg } from './fc_svg.types'

export enum FcPreconfiguredStyle {
  BEARBRICK_BLACK = 0,
  INSET = 1,
  RIM = 2
}

export interface FcGeneratorPositionElementOptions {
  outerShape?: FcShape
  innerShape?: FcShape
  backgroundColor?: FcColor
  outerShapeColor?: FcColor
  outerShapeOutlineColor?: FcColor
  outerShapeOutlineSize?: FcPercentageSize
  innerShapeColor?: FcColor
  innerShapeOutlineColor?: FcColor
  innerShapeOutlineSize?: FcPercentageSize
  innerShapeWidth?: FcPercentageSize
  outerShapeWidth?: FcPercentageSize
  text?: FcText
  imageUrl?: string | null
  innerImageUrl?: string | null
  gradient?: FcColorGradient
  fillSvg?: boolean
  insetBorderPadding?: number
}

export interface FcContainerOverlayOptions {
  imageUrl: string
  maskUrl?: string
  fillSvgColor?: FcColor
  width: FcPercentageSize
  clip?: boolean
  degrees: number
  radius: number
  padding: number
  rotate?: number
}

export interface FcContainerFragmentOptions {
  imageUrl: string
  fillSvgColor?: FcColor
  opacity?: number
  scalePercentage?: number
  gradient?: FcColorGradient
  xpad?: number
  ypad?: number
}

export interface FcPipes {
  cap?: string
  elbow?: string
  cross?: string
  tee?: string
  couple?: string
  single?: string
}

export enum FcRandomShapeColorType {
  GRADIENT = 0,
  SOLID = 1,
  TEXTURE = 2
}

export interface FcRandomShapeColor {
  clusterColorType: FcRandomShapeColorType
  color?: FcColor
  gradient?: FcColorGradient
  outlineColor?: FcColor
  outlineSize?: number
  textureImageUrl?: string | null
  textureImageSize?: number
}

export interface FcRandomShapes {
  enable?: boolean
  curveFactor?: number
  pointFactor?: number
  lineFactor?: number
  eraseSmallClusters?: boolean
  colors: FcRandomShapeColor[]
}

export interface FcPatternConfigOptions {
  gridModuleColorFillSvg: boolean
  gridModuleRandomSizes: string
  containerOuterPatternColorFillSvg: boolean
  containerOuterPatternRandomSizes: string
  containerOuterPatternShape: FcShape | null
  gridModuleShape: FcShape | null
  gridModuleImageUrl: string | null
  containerOuterPatternImageUrl: string | null
}

export interface FcPatternAssetFile {
  assetName: string
  url: string
}

export interface FcPatternConfig {
  id: string
  name: string
  containerImageUrl: string
  configuration: FcPatternConfigOptions
  assetFile: FcPatternAssetFile
}

export interface FcGeneratorOptions {
  data?: string
  baseStudioConfigId?: string
  style?: FcPreconfiguredStyle
  defaultColor?: FcColor
  defaultBackgroundColor?: FcColor
  alwaysUseDefaultColor?: boolean
  targetSize?: FcSize
  transparent?: boolean
  oldschool?: boolean
  qrdensity?: number
  qrrotate?: boolean
  margin?: number
  radiusMask?: FcPercentageSize | null
  errorCorrectionLevel?: FcErrorCorrectionLevel
  containerShape?: FcShape
  containerText?: FcText[]
  containerBorderColor?: FcColor
  containerBorderSize?: number
  containerBorderOutlineColor?: FcColor
  containerBorderOutlineSize?: number
  containerBorderGradient?: FcColorGradient
  containerEmptySpaceSize?: number
  containerOuterPatternColorFillSvg?: boolean
  containerOuterPatternColor?: FcColor
  containerOuterPatternShape?: FcShape | null
  containerOuterPatternImageUrl?: string | null
  containerOuterPatternPipes?: FcPipes
  containerOuterPatternRandomShapes?: FcRandomShapes
  containerOuterPatternRandomSizes?: string
  containerOuterPatternGradient?: FcGradient
  containerBackgroundColor?: FcColor
  containerImageUrl?: string | null
  containerImageUrlFillSvg?: boolean | null
  containerImageUrlFg?: string
  containerImageUrlOpacity?: number
  containerImageUrlFgOpacity?: number
  containerImageOverlayPrivacy?: boolean
  containerFragmentRadiusMask?: number
  containerMaskImageUrl?: string | null
  overlayRadiusMask?: number
  containerImageBackgroundOnly?: boolean
  dataGridPercentageSize?: FcPercentageSize
  containerBorderInsetEyes?: FcPercentageSize
  containerBackgroundInsetExtend?: FcPercentageSize
  privacyTextPaddingHorizontal?: number
  privacyTextPaddingVertical?: number
  containerSquareRadius?: number
  colorTranslation?: FcColorTranslation[]

  containerRimWidth?: FcPercentageSize
  containerRimText?: FcText[]
  containerRimBackgroundColor?: FcColor
  containerRimImageUrl?: string | null
  containerRimImageUrlOpacity?: number
  containerRimBorderSize?: number
  containerRimBorderColor?: FcColor
  containerRimBorderGradient?: FcColorGradient
  containerRimOutlineColor?: FcColor
  containerRimOutlineSize?: number
  containerRimPatternColorFillSvg?: boolean
  containerRimPatternColor?: FcColor
  containerRimPatternShape?: FcShape
  containerRimPatternSize?: FcPercentageSize
  containerRimPatternSpacing?: FcPercentageSize
  containerRimPatternImageUrl?: string | null

  gridModuleColorFillSvg?: boolean
  gridModuleColor?: FcColor
  gridModuleShape?: FcShape | null
  gridModuleImageUrl?: string | null
  gridModulePipes?: FcPipes
  gridModuleRandomShapes?: FcRandomShapes
  gridBackgroundColor?: FcColor
  gridGradient?: FcGradient
  gridModuleRandomSizes?: string
  gridModuleColorGradient?: FcColorGradient
  logoWidth?: FcPercentageSize
  logoHeight?: FcPercentageSize
  logoImageUrl?: string | null
  logoFillSvgColor?: FcColor
  logoModulePadding?: number
  logoOuterPadding?: number
  logoPreserveAspect?: boolean
  logoModuleMask?: string
  blendErasurePad?: number
  centerTextBlend?: boolean
  centerTextBlendErase?: boolean
  centerTextBlendModuleSize?: number
  centerTextBlendModuleShape?: FcShape
  centerTextBlendModuleColorFg?: FcColor
  centerTextBlendModuleColorBg?: FcColor
  logoBlend?: boolean
  logoBlendErase?: boolean
  logoBlendModuleSize?: number
  logoBlendModuleColorFg?: FcColor
  logoBlendModuleColorBg?: FcColor
  logoBlendModuleShape?: FcShape
  logoBlendModuleImageUrl?: string | null
  logoBlendModuleTolerationFg?: number
  logoBlendModuleTolerationBg?: number
  logoBlendModulePixelsToCheck?: number
  logoRotate?: number
  erasedExact?: boolean
  erasedExactTolerance?: number
  centerText?: FcText[]
  centerTextPadding?: number
  positionElementTopRight?: FcGeneratorPositionElementOptions
  positionElementTopLeft?: FcGeneratorPositionElementOptions
  positionElementBottomLeft?: FcGeneratorPositionElementOptions

  logoInCode?: boolean
  logoInCodeColor?: FcColor
  logoInCodePixels?: boolean
  logoInCodePixelsShape?: FcShape
  logoInCodeBitmap?: number[][]

  // no longer supported
  outerPatternAlignment?: any

  // no longer used - only gridModuleColorGradient is used now
  containerOuterPatternColorGradient?: FcColorGradient

  delay?: number
  frames?: FcGeneratorOptions[]
  overlays?: FcContainerOverlayOptions[]
  overlayBlend?: boolean
  overlayBlendModuleSize?: number
  overlayBlendErase?: boolean
  overlayBlendModuleColorFg?: FcColor
  overlayBlendModuleColorBg?: FcColor
  overlayBlendModuleShape?: FcShape
  overlayBlendModuleImageUrl?: string | null
  overlayBlendModuleTolerationFg?: number
  overlayBlendModuleTolerationBg?: number
  overlayBlendModulePixelsToCheck?: number
  overlayExpandTargetSize?: boolean

  containerFragments?: FcContainerFragmentOptions[]
  containerFragmentGradient?: FcColorGradient
  customContainerOuterPattern?: boolean

  // used to control whether a user can apply inversion to a design
  isInvertible?: boolean

  // used by studio
  cmykPicked?: boolean

  // used by studio to force vector output for EPS files
  noRasterize?: boolean

  isTVCode?: boolean
  id?: string
}

export interface FcGenerator {
  (options: FcGeneratorOptions, customBuilder?: FcBuilder, customRenderer?: FcRenderer): FcSvg
}

export const defaultPositionText: FcText = {
  text: 'FLOWCODE',
  font: 'inter_black',
  fontSize: 10.5,
  fontColor: FcColorBlack,
  position: new FcPosition(FcPositionProperty.BOTTOM, 0)
}

export const defaultPositionElementBottomLeft: FcGeneratorPositionElementOptions = {
  outerShape: FcShape.LOGO,
  innerShape: FcShape.SQUARE,
  backgroundColor: FcColorWhite,
  outerShapeColor: FcColorBlack,
  innerShapeColor: FcColorBlack,
  innerShapeWidth: 43,
  outerShapeWidth: 15,
  text: defaultPositionText,
  insetBorderPadding: 0.04
}

export const defaultPositionElementTopLeft: FcGeneratorPositionElementOptions = {
  outerShape: FcShape.SQUARE,
  innerShape: FcShape.SQUARE,
  backgroundColor: FcColorWhite,
  outerShapeColor: FcColorBlack,
  innerShapeColor: FcColorBlack,
  innerShapeWidth: 45,
  outerShapeWidth: 15,
  insetBorderPadding: -0.02
}

export const defaultPositionElementTopRight: FcGeneratorPositionElementOptions = {
  outerShape: FcShape.SQUARE,
  innerShape: FcShape.SQUARE,
  backgroundColor: FcColorWhite,
  outerShapeColor: FcColorBlack,
  innerShapeColor: FcColorBlack,
  innerShapeWidth: 45,
  outerShapeWidth: 15,
  insetBorderPadding: 0.04
}

export const optionsDefaults: FcGeneratorOptions = {
  data: '',
  targetSize: 400,
  errorCorrectionLevel: FcErrorCorrectionLevel.H,
  containerText: [
    new FcText(
      'PRIVACY.FLOWCODE.COM',
      'inter_medium',
      9,
      FcColorBlack,
      new FcPosition(FcPositionProperty.BOTTOM, 2)
    )
  ],
  containerShape: FcShape.CIRCLE,
  containerBorderColor: FcColorBlack,
  containerBorderSize: 8,
  containerEmptySpaceSize: 5,
  containerOuterPatternColorFillSvg: false,
  containerOuterPatternColor: FcColorBlack,
  containerOuterPatternShape: FcShape.SHAGGY,
  containerBackgroundColor: FcColorWhite,
  containerImageOverlayPrivacy: false,
  containerImageBackgroundOnly: false,
  dataGridPercentageSize: 72,
  gridModuleColorFillSvg: false,
  gridModuleColor: FcColorBlack,
  gridModuleShape: FcShape.SHAGGY,
  gridBackgroundColor: FcColorWhite,
  positionElementTopRight: defaultPositionElementTopRight,
  positionElementTopLeft: defaultPositionElementTopLeft,
  positionElementBottomLeft: defaultPositionElementBottomLeft,
  privacyTextPaddingHorizontal: 6,
  privacyTextPaddingVertical: 6
}

export const optionsRim: FcGeneratorOptions = {
  data: '',
  targetSize: 400,
  errorCorrectionLevel: FcErrorCorrectionLevel.H,
  containerText: [
    new FcText(
      'PRIVACY.FLOWCODE.COM',
      'inter_medium',
      9,
      FcColorBlack,
      new FcPosition(FcPositionProperty.BOTTOM, 2)
    )
  ],
  containerShape: FcShape.CIRCLE,
  containerBorderColor: FcColorBlack,
  containerBorderSize: 8,
  containerRimWidth: 20,
  containerRimBackgroundColor: FcColorWhite,
  containerRimBorderColor: FcColorBlack,
  containerRimBorderSize: 8,
  containerRimText: [
    new FcText(
      'SCAN ME',
      'inter_medium',
      18,
      FcColorBlack,
      new FcPosition(FcPositionProperty.TOP, 0)
    ),
    new FcText(
      'SCAN ME',
      'inter_medium',
      18,
      FcColorBlack,
      new FcPosition(FcPositionProperty.BOTTOM, 0)
    )
  ],
  containerEmptySpaceSize: 5,
  containerOuterPatternColorFillSvg: false,
  containerOuterPatternColor: FcColorBlack,
  containerOuterPatternShape: FcShape.SHAGGY,
  containerBackgroundColor: FcColorWhite,
  containerImageOverlayPrivacy: false,
  containerImageBackgroundOnly: false,
  dataGridPercentageSize: 72,
  gridModuleColorFillSvg: false,
  gridModuleColor: FcColorBlack,
  gridModuleShape: FcShape.SHAGGY,
  gridBackgroundColor: FcColorWhite,
  positionElementTopRight: defaultPositionElementTopRight,
  positionElementTopLeft: defaultPositionElementTopLeft,
  positionElementBottomLeft: defaultPositionElementBottomLeft
}

export const optionsInset: FcGeneratorOptions = {
  ...optionsDefaults,
  radiusMask: 78,
  containerText: [
    {
      font: 'inter_medium',
      text: 'PRIVACY.FLOWCODE.COM',
      fontSize: 7,
      position: new FcPosition(FcPositionProperty.BOTTOM, 2),
      fontColor: '#000'
    }
  ],
  containerBorderSize: 7.5,
  dataGridPercentageSize: 72,
  positionElementTopLeft: {
    innerShape: FcShape.SQUARE,
    outerShape: FcShape.SQUARE,
    innerShapeWidth: 45,
    outerShapeWidth: 15,
    insetBorderPadding: 0.11,
    outerShapeOutlineSize: 0,
    backgroundColor: FcColorWhite,
    outerShapeColor: FcColorBlack,
    innerShapeColor: FcColorBlack
  },
  containerEmptySpaceSize: 6,
  containerRimPatternSize: 85,
  positionElementTopRight: {
    innerShape: FcShape.SQUARE,
    outerShape: FcShape.SQUARE,
    innerShapeWidth: 45,
    outerShapeWidth: 15,
    insetBorderPadding: 0.11,
    outerShapeOutlineSize: 0,
    backgroundColor: FcColorWhite,
    outerShapeColor: FcColorBlack,
    innerShapeColor: FcColorBlack
  },
  containerBorderInsetEyes: 7,
  containerImageUrlOpacity: 1,
  positionElementBottomLeft: {
    text: {
      font: 'inter_black',
      text: 'FLOWCODE',
      fontSize: 12,
      position: new FcPosition(FcPositionProperty.BOTTOM, 0),
      fontColor: '#000000'
    },
    innerShape: FcShape.SQUARE,
    outerShape: FcShape.LOGO,
    innerShapeWidth: 45,
    outerShapeWidth: 15,
    insetBorderPadding: 0.11,
    outerShapeOutlineSize: 0,
    backgroundColor: FcColorWhite,
    outerShapeColor: FcColorBlack,
    innerShapeColor: FcColorBlack
  }
}

export const optionsInsetExtend: FcGeneratorOptions = {
  ...optionsInset,
  containerBackgroundInsetExtend: 4
}

export enum FcGeneratorDownloadType {
  PNG = 'png',
  JPG = 'jpg',
  SVG = 'svg',
  PDF = 'pdf',
  EPS = 'eps'
}
