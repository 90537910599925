import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { Modal } from '@dtx-company/shared-components/src/components/organisms/Modal'
import { ModalVariants } from '@dtx-company/shared-components/src/types/organisms'
import { useFlowpageModalState } from './hooks'
import Map from '../map'

export const FlowpageModals: FC = () => {
  const { location, openLocationModal, setOpenLocationModal } = useFlowpageModalState()
  return (
    <Modal
      variant={ModalVariants.DESKTOP}
      delay={250}
      title="Address"
      top={['40%', '40%', null]}
      bottom={[0, 0, null]}
      open={openLocationModal}
      onClose={() => setOpenLocationModal(false)}
    >
      <Box
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        maxWidth="500px"
        margin={'58px 0 40px'}
      >
        <Map location={location || ''} width="300px" height="130px" />
      </Box>
    </Modal>
  )
}
