import {
  LinkInfoFragment,
  UnauthPageQuery
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { ReactNode } from 'react'
import { context } from './context'
import { useCreatePageValue } from './useCreatePageValue'

type PageDisplayProviderProps = {
  value?: {
    page?: PageType | UnauthPageQuery['unauthPage']
    gate?: LinkInfoFragment
  }
  children: ReactNode
}

const Provider = context.Provider
export const PageDisplayProvider = ({
  value = { page: undefined, gate: undefined },
  children
}: PageDisplayProviderProps): JSX.Element => {
  const pageDisplayValue = useCreatePageValue(value)
  return <Provider value={pageDisplayValue}>{children}</Provider>
}
