import {
  LinkType,
  PageType,
  UnauthPage,
  UnuathPageQuerySlot
} from '@dtx-company/flow-codegen/src/page/typeUtils'
import { Maybe } from '@dtx-company/flow-codegen/src/page/generated.types'
import { SlotType } from '../../../containers/settings/settings.types'
import { getFBPixelLinks } from '../../../utils/getFBPixelLinks'

type BuildPixelListReturnType = {
  pixels: UnuathPageQuerySlot[]
  fbPixels: Maybe<LinkType>[]
}
export const buildPixelList = (page: PageType | UnauthPage | null): BuildPixelListReturnType => {
  const pixels: UnuathPageQuerySlot[] = []
  const slots = page?.slots
  const fbPixels: Maybe<LinkType>[] = getFBPixelLinks(page?.links ?? [])
  if (slots) {
    slots.forEach(value => {
      if (value && value.data?.isEnabled && value.slotMeta?.type === SlotType.SETTINGS_PIXEL) {
        pixels.push(value)
      }
    })
  }
  return { pixels, fbPixels } // Todo: Unify once fbPixel are stored as slots - AF
}

// Todo: remove once SEO meta tags are stored as slots - AF
export const getSEOMetaTagsLink = (links: Maybe<LinkType>[] | null | undefined): Maybe<LinkType> =>
  links?.find(link => link?.provider === 'widget' && link?.type === 'pageMeta') ?? null
