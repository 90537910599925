import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC, useMemo } from 'react'
import { LinkInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { WidgetType } from '../../../widgets/types/widgetType'
import { getWidgetComponent } from '../../../widgets/getWidgetComponent'

export interface FlowpageLinkProps {
  link: LinkType
  isPreview?: boolean
  previewLink: LinkInfoFragment | null
}

export const FlowpageLink: FC<FlowpageLinkProps> = ({ link, isPreview, previewLink }) => {
  const Component = useMemo(
    () => getWidgetComponent(link.provider as LinkProvider, link.type as WidgetType),
    [link.provider, link.type]
  )
  const currentLinkIsPreviewLink = previewLink?.id === link.id

  const isCRM = link.type === 'contactCollection'
  const isCRMGate =
    isPreview && isCRM && currentLinkIsPreviewLink
      ? previewLink?.displayType === 'popup'
      : link.displayType === 'popup'
  const isAgeGate =
    isPreview && currentLinkIsPreviewLink
      ? previewLink?.type === 'ageGate'
      : link.type === 'ageGate'

  const isGate = isCRMGate || isAgeGate

  return Component && !isGate && link.active ? (
    <Box key={link.id} id={link.id} width="100%" display="block">
      <Component link={currentLinkIsPreviewLink ? previewLink : link} preview={isPreview} />
    </Box>
  ) : null
}
