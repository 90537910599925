import { ArrowDown, ArrowUp, Tooltip, theme } from '@dtx-company/shared-components/src'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import {
  Button,
  SecondaryButton
} from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { PixelFormFieldNames, PixelProps } from './pixel.types'
import { PixelType } from '../../../containers/settings/settings.types'
import { RadioButton } from '@dtx-company/shared-components/src/components/atoms/RadioButton/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { Toggle } from '@dtx-company/shared-components/src/components/atoms/Toggle/index'
import { checkmarkGray, checkmarkGreen, questionMarkBlack, trashCanRed } from '@app/code/src/assets'
import { usePixelState } from './pixel.hooks'
import styled from 'styled-components'

const StyledForm = styled.form`
  width: 100%;
`

export const Pixel = ({
  id,
  data,
  token,
  pageId,
  mutate,
  handleRemove
}: PixelProps): JSX.Element => {
  const {
    labelText,
    labelTextId,
    handleToggle,
    isEnabled,
    handleSubmit,
    onSubmit,
    errors,
    register,
    setDetailsOpen,
    detailsOpen,
    values,
    setValue,
    isSaveButtonDisabled,
    isSubmitting,
    isDirty,
    typedData
  } = usePixelState({ id, data, token, pageId, mutate, handleRemove })
  return (
    <Box flexDirection="column">
      <Text id={labelTextId}>{labelText}</Text>
      <Spacer mb="8px" />
      <Box alignItems="center">
        <Toggle checked={isEnabled} onChange={handleToggle} ariaLabelledBy={labelTextId} />
        <Spacer ml="8px" />
        <Text>{isEnabled ? 'On' : 'Off'}</Text>
      </Box>
      <Spacer mb="8px" />
      <Box
        display={isEnabled ? 'flex' : 'none'}
        borderRadius={theme.radii[5]}
        backgroundColor={theme.colors.secondary.backgroundDark}
        padding={'16px'}
      >
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="text"
            label="Pixel Name"
            labelProps={{ fontSize: '12px', marginBottom: '8px' }}
            placeholder="Enter Name"
            inputProps={{ ...register(PixelFormFieldNames.NAME) }}
            width="100%"
            maxWidth="auto"
            error={!!errors[PixelFormFieldNames.NAME]?.message}
            helperText={errors[PixelFormFieldNames.NAME]?.message}
            helperTextProps={{ height: 0 }}
          />
          <Spacer mb="16px" />
          <Button
            colorVariant="invisible"
            type="button"
            width="auto"
            onClick={() => setDetailsOpen(open => !open)}
            color="primary.flowBlue"
            px="0px"
          >
            <Text color="primary.flowBlue" variant="button/input">
              {detailsOpen ? 'Collapse' : 'Expand'} Information
            </Text>
            <Spacer mr="8px" />
            {detailsOpen ? <ArrowUp /> : <ArrowDown />}
          </Button>
          <Spacer mb="16px" />
          <Box flexDirection="column" collapsible={{ open: detailsOpen, maxHeight: 200 }}>
            <Box flexDirection="column" role="radiogroup" aria-labelledby="pixel-type-label">
              <Text id="pixel-type-label" variant="general/captionAndLabel" mb="8px">
                Pixel Type
              </Text>
              <RadioButton
                error={Boolean(errors[PixelFormFieldNames.PixelType])}
                inputProps={{
                  ...register(PixelFormFieldNames.PixelType),
                  tabIndex: 0
                }}
                checked={values[PixelFormFieldNames.PixelType] === 'iframe'}
                name="pixelType"
                onChange={e => {
                  setValue(PixelFormFieldNames.PixelType, e.target.value as PixelType)
                }}
                label="iFrame"
                value={PixelType.iframe}
              />
              <Spacer mb="8px" />
              <RadioButton
                error={Boolean(errors[PixelFormFieldNames.PixelType])}
                checked={values[PixelFormFieldNames.PixelType] === 'image'}
                inputProps={{
                  ...register(PixelFormFieldNames.PixelType)
                }}
                name="pixelType"
                onChange={e => {
                  setValue(PixelFormFieldNames.PixelType, e.target.value as PixelType)
                }}
                label="Image"
                value={PixelType.image}
              />
            </Box>
            <Spacer mb="16px" />
            <Box>
              <Text id="pixel-tracking" as="label" variant="input/label">
                Pixel tracking URL
              </Text>
              <Spacer ml="8px" />
              <Tooltip
                id="pixel-help-text"
                layout="up"
                content="In order to set up your pixel, all we need is the URL contained in the src attribute."
                buttonImage={questionMarkBlack}
              />
            </Box>
            <Spacer mb="8px" />
            <Input
              type="text"
              ariaLabelledBy="pixel-tracking"
              placeholder="Paste here"
              inputProps={{ ...register(PixelFormFieldNames.SRC) }}
              width="100%"
              maxWidth="auto"
              error={!!errors[PixelFormFieldNames.SRC]?.message}
              helperText={errors[PixelFormFieldNames.SRC]?.message}
            />
            <Spacer mb="16px" />
          </Box>
          <Box>
            <SecondaryButton
              type="submit"
              sizeVariant="mobileFullWidth"
              disabled={isSaveButtonDisabled}
              borderColor="status.successDark"
              color="status.successDark"
              width="50%"
              height="44px"
              justifyContent="space-evenly"
              padding="0px 25px"
              label="Save"
              icon={isDirty ? checkmarkGreen : checkmarkGray}
              iconProps={{ width: '20px', height: '20px' }}
            />
            <Spacer mr="8px" />
            <SecondaryButton
              type="button"
              sizeVariant="mobileFullWidth"
              disabled={isSubmitting}
              borderColor="status.errorDark"
              color="status.errorDark"
              width="50%"
              height="44px"
              onClick={() => handleRemove(id, typedData.isNotSaved)}
              justifyContent="space-evenly"
              padding="0px 25px"
              label="Delete"
              icon={trashCanRed}
              iconProps={{ width: '20px', height: '20px' }}
            />
          </Box>
        </StyledForm>
      </Box>
      <Spacer mb={'24px'} />
    </Box>
  )
}
