import {
  ALERT_BUTTON_POSITION_VARIANTS,
  ALERT_BUTTON_SIZE_VARIANTS,
  ALERT_POSITION_VARIANTS,
  ALERT_SHAPE_VARIANTS,
  ALERT_SIZE_VARIANTS,
  ALERT_TYPE_VARIANTS
} from '../../../constants/alert-variants'
import { AlertButtonPosition, AlertButtonSizeOptions } from '../../..'
import { AlertProps } from '.'
import { Box } from '../../atoms/Box/index'
import { Button } from '../../atoms/Button/index'
import { height, margin, padding, variant, width } from 'styled-system'
import styled from 'styled-components'

export const AlertBox = styled(Box)<AlertProps>`
  position: absolute;
  display: block;
  -webkit-font-smoothing: antialiased;
  ${variant({
    prop: 'type',
    variants: { ...ALERT_TYPE_VARIANTS }
  })}
  ${variant({
    prop: 'shapeVariant',
    variants: { ...ALERT_SHAPE_VARIANTS }
  })}
  ${variant({
    prop: 'positonVariant',
    variants: { ...ALERT_POSITION_VARIANTS }
  })}
  ${variant({
    prop: 'sizeVariant',
    variants: { ...ALERT_SIZE_VARIANTS }
  })}
  ${width}
  ${margin}
  ${padding}
`

interface ButtonProps {
  positionVariants?: AlertButtonPosition | AlertButtonPosition[]
  sizeVariants?: AlertButtonSizeOptions | AlertButtonSizeOptions[]
}

export const AlertButtonCtr = styled(Box)<ButtonProps>`
  ${variant({
    prop: 'positionVariants',
    variants: { ...ALERT_BUTTON_POSITION_VARIANTS }
  })}
`

export const AlertButton = styled(Button)<ButtonProps>`
  font-weight: bold;
  ${variant({
    prop: 'sizeVariants',
    variants: { ...ALERT_BUTTON_SIZE_VARIANTS }
  })}
  ${margin}
  ${padding}
  ${width}
  ${height}
`
