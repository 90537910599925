import { AlertBox, AlertButton, AlertButtonCtr } from './styles'
import {
  AlertButtonPosition,
  AlertButtonSizeOptions,
  AlertPostionOptions,
  AlertShapeOptions,
  AlertSizeOptions,
  AlertTypeOptions
} from '../../..'
import { Box } from '../../atoms/Box/index'
import { ButtonColorOptions } from '../../../constants'
import { Icon } from '../../atoms/Icon/index'
import { LayoutProps, PositionProps, SpaceProps, TextAlignProps, WidthProps } from 'styled-system'
import { Text } from '../../atoms/Text/index'
import { useEffect, useState } from 'react'
import CloseIcon from '../../../static/close.svg'

export type AlertButtonProps = {
  disabled?: boolean
  icon?: string
  iconAlt?: string
  iconProps?: LayoutProps & SpaceProps & PositionProps
  label?: string
  loading?: boolean
  onClick?: () => void
  sizeVariants?: AlertButtonSizeOptions | AlertButtonSizeOptions[]
  type?: 'button' | 'submit' | 'reset'
  width?: string
  height?: string | string[]
  p?: string[]
}

export interface AlertHTMLProps {
  id?: string
}

export interface AlertProps {
  buttonsProps?: AlertButtonProps[] & SpaceProps[] & LayoutProps[]

  closeIcon?: boolean
  dismiss?: number | 'none'
  onClose?: () => void
  positonVariant?: AlertPostionOptions | AlertPostionOptions[]
  buttonsPositionVariants?: AlertButtonPosition | AlertButtonPosition[]
  shapeVariant?: AlertShapeOptions | AlertShapeOptions[]
  sizeVariant?: AlertSizeOptions | AlertSizeOptions[]
  type?: AlertTypeOptions
}

export interface AlertContentProps {
  title?: string
  message: string
}

export type AlertStyleProps = WidthProps & TextAlignProps & SpaceProps
export type CombinedAlertProps = AlertProps & AlertContentProps & AlertStyleProps & AlertHTMLProps

export const Alert = ({
  buttonsProps,
  buttonsPositionVariants = 'newlineLeft',
  closeIcon = false,
  dismiss = 5000,
  message,
  onClose,
  positonVariant = 'topCenter',
  shapeVariant = 'curved',
  sizeVariant = 'lg',
  textAlign = 'left',
  title,
  type = 'primary',
  ...rest
}: CombinedAlertProps): JSX.Element => {
  const [buttonColorVariant, setButtonColorVariant] = useState<ButtonColorOptions>()
  const [open, setOpen] = useState(true)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (dismiss !== 'none') {
      timer = setTimeout(() => {
        setOpen(false)
      }, dismiss)
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [dismiss])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      const btnColorVariant = (type: AlertTypeOptions): ButtonColorOptions => {
        switch (type) {
          case 'primary':
            return 'primaryOnDark'
          case 'tertiary':
            return 'tertiary'
          case 'secondary':
            return 'secondaryOnDark'
          default:
            return 'primaryOnDark'
        }
      }
      setButtonColorVariant(btnColorVariant(type))
    }
    return () => {
      mounted = false
    }
  }, [type])

  const handleCloseIcon = (): void => {
    if (onClose) onClose()
    setOpen(false)
  }

  return (
    <>
      {open && (
        <AlertBox
          type={type}
          shapeVariant={shapeVariant}
          positonVariant={positonVariant}
          sizeVariant={sizeVariant}
          aria-label="alert"
          {...rest}
        >
          <>
            <Box justifyContent="space-between" alignItems={title ? 'start' : 'center'}>
              <Box display="block" width="100%">
                {title && (
                  <Text
                    fontSize="inherit"
                    fontWeight="bold"
                    color="inherit"
                    lineHeight="inherit"
                    marginBottom="5px"
                    aria-label="alert-title"
                    textAlign={textAlign}
                  >
                    {title}
                  </Text>
                )}
                <Text
                  color="inherit"
                  fontSize="inherit"
                  lineHeight="inherit"
                  textAlign={textAlign}
                  aria-label="alert-message"
                  fontWeight={title ? 'normal' : 'bold'}
                >
                  {message}
                </Text>
              </Box>
              {(onClose || closeIcon) && buttonsPositionVariants !== 'inline' && (
                <Icon
                  src={CloseIcon}
                  alt="close"
                  height="16px"
                  width="16px"
                  button={true}
                  onClick={handleCloseIcon}
                  aria-label="alert-close"
                />
              )}
              {buttonsProps?.length &&
                buttonsPositionVariants === 'inline' &&
                buttonsProps.map(buttonProps => {
                  const { onClick, sizeVariants = 'lg', label, ...space } = buttonProps
                  return (
                    <Box key={label} ml="12px">
                      <AlertButton
                        sizeVariants={sizeVariants}
                        colorVariant={buttonColorVariant}
                        onClick={onClick}
                        label={label}
                        {...space}
                      />
                    </Box>
                  )
                })}
            </Box>

            {buttonsProps?.length &&
              buttonsPositionVariants !== 'inline' &&
              buttonsProps.map(buttonProps => {
                const { onClick, sizeVariants = 'lg', label, ...space } = buttonProps
                return (
                  <AlertButtonCtr key={label} positionVariants={buttonsPositionVariants} mt="12px">
                    <AlertButton
                      sizeVariants={sizeVariants}
                      colorVariant={buttonColorVariant}
                      onClick={onClick}
                      label={label}
                      {...space}
                    />
                  </AlertButtonCtr>
                )
              })}
          </>
        </AlertBox>
      )}
    </>
  )
}
