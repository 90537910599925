import { FlowpageContainer } from '../../../components/layout/FlowpageContainer'
import { FlowpageSlugContainer } from '@app/page/src/containers/slug'
import { GetServerSideProps, NextPage } from 'next/types'
import {
  LinkInfoFragment,
  UnauthPageQuery
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { PageDisplayConsumer, PageDisplayProvider } from '@app/page/src/context/page-display'
import { PageGateContainer } from '@app/page/src/containers/slug/gate'
import { PageTracker } from '@app/page/src/components/PageTracker'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import {
  checkCustomFooterEnabled,
  checkRemoveBrandingEnabled
} from '@app/page/src/components/RemoveBranding/utils'
import { flowpageUrlRoot } from '@dtx-company/true-common/src/constants/page'
import { getScreenshot } from '../../../utils/images'
import { getServerSidePropsUnauthPage } from '../../../utils/ssr/link-header/page-slug'
import { prependProtocol } from '@dtx-company/inter-app/src/utils/misc/prependProtocol'
import { slugToUrlFullRoute } from '@dtx-company/true-common/src/utils/flowpage'
import { useRedirectOnAuthChanges } from '@dtx-company/inter-app/src/hooks/auth/useRedirectOnAuthChanges'

export const getServerSideProps: GetServerSideProps = getServerSidePropsUnauthPage

interface FlowpageProps {
  page: UnauthPageQuery['unauthPage']
  gate: LinkInfoFragment | null
  headers?: Record<string, string | null | undefined>
}

const Flowpage: NextPage<FlowpageProps> = ({ page, gate, headers }) => {
  const host = headers?.host
  const domain: string = host && page?.domain ? prependProtocol(host) : flowpageUrlRoot
  const pageUrl = domain + page?.slugName?.toLowerCase()
  const canonicalUrl = slugToUrlFullRoute(page?.slugName, page?.domain?.domain)
  const showWhiteLabelFooter = checkRemoveBrandingEnabled(page?.slots)
  const customFooter = checkCustomFooterEnabled(page?.slots)

  useRedirectOnAuthChanges()

  return (
    <FlowpageContainer
      injectedMeta={{
        title:
          page && page.displayName
            ? `${page.displayName}${page.displayName?.endsWith('s') ? "'" : "'s"} Flowpage`
            : 'Flowpage',
        description: page?.caption || 'All of your links, all in one place.',
        image: page?.slugName ? getScreenshot(canonicalUrl, 1080, 650) : undefined,
        url: pageUrl,
        canonical: canonicalUrl
      }}
      customFooter={customFooter}
      whiteLabelFooter={showWhiteLabelFooter}
      minimalFooter={!customFooter || !showWhiteLabelFooter}
      page={Routes.FLOWPAGE}
    >
      <PageDisplayProvider value={{ page, gate: gate || undefined }}>
        <PageTracker headers={headers} />
        <PageDisplayConsumer>
          {value => {
            if (!value?.gateConditionComplete) {
              return (
                <PageGateContainer
                  gate={gate || undefined}
                  pageLinksLength={page?.links?.length ?? 0}
                  blurredPage={{
                    profileImage: page?.profileImage,
                    displayName: page?.displayName,
                    theme: page?.theme
                  }}
                />
              )
            } else {
              return <FlowpageSlugContainer page={page} referrer={headers?.referrer} />
            }
          }}
        </PageDisplayConsumer>
      </PageDisplayProvider>
    </FlowpageContainer>
  )
}

export default Flowpage
