import { Alert, AlertButtonProps, theme as defaultTheme } from '@dtx-company/shared-components/src'
import { FC } from 'react'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { defaultPageAttributes } from '@dtx-company/true-common/src/constants/page'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { usePageCanBePublished } from '../../hooks/usePageCanBePublished'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

const StyledAlert = styled(Alert)`
  z-index: 10;
  top: 0px;
  @media (min-width: 800px) {
    button {
      p {
        font-size: 15px;
      }
    }
  }
  p {
    font-weight: 600;
    letter-spacing: normal;
    font-family: ${defaultTheme.fontFamily};
  }

  @media (max-width: 600px) {
    border-radius: 0;
  }
`

interface PublishBannerProps {
  pageId: string
}

const PublishBanner: FC<PublishBannerProps> = ({ pageId }) => {
  const router = useRouter()
  const entity = router.query.templateId ? 'template' : 'page'
  const isTempPage = pageId === defaultPageAttributes.id
  const userHasLinkAndBio = usePageCanBePublished()
  const { t } = useTranslation('flowpage-edit-banner')

  const message = (() => {
    if (isTempPage) {
      if (userHasLinkAndBio) return t('pageHasLinkAndDescription')
      else return t('pageHasIsMissingLinkAndDescription')
    } else {
      return t('pageIsLive', { entityType: entity })
    }
  })()

  const buttons: AlertButtonProps[] = [
    {
      label: t('editButtonCta'),
      p: ['0 10px', '0 30px'],
      width: '44px',
      height: ['30px', '35px'],
      onClick: (): void => {
        fireAnalyticsEvent('Flowpage_PublishBanner_EditButton_Clicked')
        router.query.templateId
          ? router.push({
              query: { id: router.query.templateId, previewed: true },
              pathname: Routes.PAGE_TEMPLATE_EDITOR
            })
          : router.push({
              query: { previewed: true, id: pageId },
              pathname: Routes.PAGE_EDITOR
            })
      }
    }
  ]

  return (
    <StyledAlert
      type="primary"
      positonVariant="topCenter"
      message={message}
      buttonsPositionVariants="inline"
      buttonsProps={buttons}
      dismiss="none"
      sizeVariant={['sm', 'lg']}
      width={['100%', '600px']}
      pl={['16px', '22px']}
      mt={[null, null, '10px']}
    />
  )
}

export default PublishBanner
