import { BlurredPagePreview } from '../../../components/PageGate/BlurredPagePreview'
import { FC } from 'react'
import { Modal } from '@dtx-company/shared-components/src'
import { ModalVariants } from '@dtx-company/shared-components/src/types'
import { PageGateComponent } from '../../../components/PageGate/PageGate'
import { PageGateContainerProps } from './types'
import { ThemeProvider } from 'styled-components'
import { usePageGateContainerState } from './hooks/usePageGateContainerState'

export const PageGateContainer: FC<PageGateContainerProps> = ({
  gate,
  blurredPage,
  pageLinksLength
}) => {
  const {
    userTheme,
    handleModalClose,
    isGateOptional,
    gateConditionComplete,
    wrapperProps,
    isGateCRMFormSubmitted
  } = usePageGateContainerState({ gate, blurredPage })

  return (
    <ThemeProvider theme={userTheme}>
      <BlurredPagePreview {...blurredPage} linksLength={pageLinksLength} />
      <Modal
        disableAutoClose={!isGateOptional && !isGateCRMFormSubmitted}
        maxWidth="500px"
        wrapperProps={wrapperProps}
        onClose={handleModalClose}
        hideCloseButton={!isGateOptional && !isGateCRMFormSubmitted}
        variant={ModalVariants.DESKTOP}
        open={!gateConditionComplete}
      >
        <PageGateComponent gate={gate} />
      </Modal>
    </ThemeProvider>
  )
}
