import { FC } from 'react'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import Head from 'next/head'

export const SEOMetaTagsHeadComponent: FC<{ link: LinkType }> = ({ link }) => {
  const actionData = link?.actionData

  const TitleTags: FC = () => {
    return (
      <Head>
        <title key="title">{actionData.title}</title>
        <meta name="title" content={actionData.title} key="meta-title" />
        <meta property="og:title" content={actionData.title} key="og-title" />
        <meta property="twitter:title" content={actionData.title} key="twitter-title" />
      </Head>
    )
  }

  const DescTags: FC = () => {
    return (
      <Head>
        <meta name="title" content={actionData.title} key="meta-title" />
        <meta name="description" content={actionData.description} key="meta-desc" />
        <meta property="og:description" content={actionData.description} key="og-desc" />
        <meta property="twitter:description" content={actionData.description} key="twitter-desc" />
        <meta property="twitter:card" content={actionData.description} key="twitter-card" />
      </Head>
    )
  }

  return (
    <>
      {actionData && actionData.title && <TitleTags />}
      {actionData && actionData.description && <DescTags />}
    </>
  )
}
