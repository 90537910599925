import { FLOWTAG_SHOW_BANNER_PAGETYPES } from '../GetFlowtag/GetFlowtag.constants'
import { FlowPageProps, FlowPageState, FlowpageModalState } from './types'
import { FontFamilies } from '@dtx-company/true-common/src/constants/page'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { ModalTypes } from '@dtx-company/inter-app/src/redux/slices/modal'
import { UnauthPageQueryLink } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { WIDGET_INFO } from '../../widgets/manifest'
import { WidgetType } from '../../widgets/types/widgetType'
import { checkRemoveBrandingEnabled } from '../../../components/RemoveBranding/utils'
import { getCustomThemeValues } from '../../../utils/theme'
import { parseLinks, sortLinks } from '../../../utils/main'
import { removeNulls } from '@dtx-company/true-common/src/utils/objects'
import { theme } from '@dtx-company/shared-components/src'
import { useEffect } from 'react'
import { useGetPreviewLink } from '../../../hooks/useGetPreviewLink'
import { useIsNewPreviewLink } from '../../../hooks/useIsNewPreviewLink'
import { useModal } from '@dtx-company/inter-app/src/redux/slices/utils'
import { usePageDisplayContext } from '../../../context/page-display'
import { useRouter } from 'next/router'

export function useScrollToWidget(): void {
  const router = useRouter()
  useEffect(() => {
    let scrollTo = router.query.featured

    if (typeof scrollTo === 'object') {
      scrollTo = scrollTo[0]
    }
    if (scrollTo) {
      const el = document.querySelectorAll(`[id$="${scrollTo}"]`)[0]
      el?.scrollIntoView()
    }
  }, [router.query.featured])
}
export function useFlowPageContentState(props: FlowPageProps): FlowPageState {
  const userLinks = removeNulls(props.page.links || [])
  const { setUnauthTheme } = usePageDisplayContext()
  const linkObj = parseLinks(userLinks)
  const { id, slugName } = props.page
  const { contact, links } = linkObj

  const { style, primaryColor, contrastText, baseColor, baseContrastText } = getCustomThemeValues(
    props.page?.theme
  )
  const backgroundImgUrl = props.page?.theme?.backgroundImgUrl
  const fontFamily = props.page?.theme?.fontFamily ?? FontFamilies.Inter
  const userTheme = {
    ...theme,
    fontFamily: `'${fontFamily}',${theme.fontFamily}`,
    colors: {
      ...theme.colors,
      flowpage: { style, primaryColor, contrastText, backgroundImgUrl, baseColor, baseContrastText }
    }
  }

  const [fullPageLinks, cardLinks] = links.reduce(
    (acc: UnauthPageQueryLink[][], link) => {
      if (link.provider === LinkProvider.WIDGET) {
        const widgetType = link.type as WidgetType
        if (!(WIDGET_INFO[widgetType]?.disableFlowpagePreview && props.preview)) {
          if (WIDGET_INFO[widgetType]?.isFullPage) {
            acc[0].push(link)
          } else {
            acc[1].push(link)
          }
        }
      } else {
        acc[1].push(link)
      }
      return acc
    },
    [[], []]
  )
  const sortedCardLinks = sortLinks(cardLinks)

  const showBanner = !props.preview && props.isOwnPage
  useScrollToWidget()
  useEffect(() => {
    if (props.page.theme) {
      setUnauthTheme(props.page.theme)
    }
  }, [setUnauthTheme, props.page.theme])

  const previewLink = useGetPreviewLink()
  const isNewPreviewLink = useIsNewPreviewLink()

  const pageContactInfo =
    previewLink?.id === contact?.[0]?.id ||
    (isNewPreviewLink && previewLink?.type === 'contactInfo')
      ? previewLink
      : contact?.[0]
  const showFlowtagBanner = props?.page?.pageType
    ? FLOWTAG_SHOW_BANNER_PAGETYPES.includes(props?.page?.pageType)
    : false
  const isWhiteLabeled = checkRemoveBrandingEnabled(props?.page?.slots)
  return {
    ...props,
    userTheme,
    showBanner,
    contrastText,
    slugName,
    id,
    sortedCardLinks,
    linkObj,
    contact: [pageContactInfo],
    fullPageLinks,
    isWhiteLabeled: isWhiteLabeled,
    showFlowtagBanner
  }
}

export function useFlowpageModalState(): FlowpageModalState {
  const {
    modalOpen: openLocationModal,
    setOpen,
    setClose,
    props: modalProps
  } = useModal(ModalTypes.LOCATION)

  const setOpenLocationModal = (open: boolean, location?: string): void => {
    if (open && location) {
      setOpen({
        location
      })
      return
    }
    setClose()
  }

  return {
    openLocationModal,
    setOpenLocationModal,
    location: (modalProps as Record<string, string>)?.location
  }
}
