import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { FooterLink } from '../FooterLink'
import { MobileFooterWrapper } from '../styles'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { UnauthSlot } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { desktopFooterData } from './footer-data/desktop'
import { mobileFooterData } from './footer-data/mobile'
import Image from 'next/image'

const TermsAndService: FC = () => (
  <Box justifyContent={'center'} alignItems="center" width="100%" gridArea="trademark">
    <Text fontSize={'10px'} color="primary.white">
      Flowcode is a trademark of the dtx company&nbsp;|&nbsp; &copy;&nbsp;
      {`${new Date().getFullYear()} the dtx company`}
    </Text>
  </Box>
)
export const MinimalMobileFooter: FC<{ flowpageUrl?: string }> = ({ flowpageUrl }) => {
  return (
    <MobileFooterWrapper role="contentinfo" padding="4px">
      <Box margin="0px" width="100%" padding="0px 8px" justifyContent="space-between">
        {mobileFooterData(flowpageUrl).map((section, index) => (
          <Box
            key={`section-${index}`}
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
          >
            {section.map(link => (
              <FooterLink key={link.href} {...link}>
                <Text variant="body/small" color="white">
                  {link.label}
                </Text>
              </FooterLink>
            ))}
          </Box>
        ))}
      </Box>
      <TermsAndService />
    </MobileFooterWrapper>
  )
}

export const MinimalDesktopFooter: FC<{ flowpageUrl?: string }> = ({ flowpageUrl }) => {
  return (
    <Box
      role="contentinfo"
      zIndex={9}
      flexDirection="column"
      padding="24px 24px 12px 24px"
      justifyContent="space-between"
      height="80px"
      display={['none', 'none', 'flex']}
      bg="primary.black"
      gridArea="footer"
    >
      <Box alignItems="center" width="100%" justifyContent="space-between">
        <FooterLink href={Routes.INDEX}>
          <Image
            height={16}
            width={120}
            src="/logos/flowcodeTM_horizontal_white.svg"
            alt="Return to homepage"
          />
        </FooterLink>
        <Box>
          {desktopFooterData(flowpageUrl).map(link => (
            <FooterLink key={link.href} {...link}>
              <Text variant="body/small" color="white">
                {link.label}
              </Text>
            </FooterLink>
          ))}
        </Box>
      </Box>
      <TermsAndService />
    </Box>
  )
}

export const CustomFooter: FC<{
  customFooter: UnauthSlot
}> = ({ customFooter }) => {
  const privacyPolicyUrl = customFooter?.data?.privacyPolicyUrl
  const termsOfServiceUrl = customFooter?.data?.termsOfServiceUrl
  return (
    <Box
      role="contentinfo"
      zIndex={9}
      flexDirection="column"
      padding="12px"
      justifyContent="center"
      height="40px"
      bg="primary.black"
      gridArea="footer"
      alignItems="center"
      width="100%"
    >
      <Box>
        {privacyPolicyUrl && (
          <FooterLink href={privacyPolicyUrl}>
            <Text variant="body/small" color="white">
              Privacy Policy
            </Text>
          </FooterLink>
        )}
        {privacyPolicyUrl && termsOfServiceUrl && <Spacer mr="16px" />}
        {termsOfServiceUrl && (
          <FooterLink href={termsOfServiceUrl}>
            <Text variant="body/small" color="white">
              Terms of Use
            </Text>
          </FooterLink>
        )}
      </Box>
    </Box>
  )
}

export const MinimalFooter: FC<{ route?: string }> = ({ route }) => {
  const flowpageUrl = route ? FLOWCODE_ROOT + route : undefined
  return (
    <>
      <MinimalMobileFooter flowpageUrl={flowpageUrl} />
      <MinimalDesktopFooter flowpageUrl={flowpageUrl} />
    </>
  )
}
