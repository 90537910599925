import { useDebugValue, useState } from 'react'
import { useEnhancedEffect } from '../useEnhancedEffect'

interface MediaOptions {
  defaultMatches?: boolean
  matchMedia?: (query: string) => MediaQueryList
  noSsr?: boolean
  ssrMatchMedia?: (query: string) => MediaQueryList
}

/**
 * @deprecated use `useMediaQuery` imported from `@mui/material` instead
 */
export function useMediaQuery(queryInput: string, options: MediaOptions = {}): boolean {
  const query = queryInput.replace(/^@media( ?)/m, '')

  const supportMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'

  const {
    defaultMatches = false,
    matchMedia = supportMatchMedia ? window.matchMedia : null,
    noSsr = false,
    ssrMatchMedia = null
  } = options

  const [match, setMatch] = useState(() => {
    if (noSsr && supportMatchMedia && matchMedia) {
      return matchMedia(query).matches
    }
    if (ssrMatchMedia) {
      return ssrMatchMedia(query).matches
    }

    // Once the component is mounted, we rely on the
    // event listeners to return the correct matches value.
    return defaultMatches
  })

  useEnhancedEffect(() => {
    let active = true

    if (!supportMatchMedia) {
      return undefined
    }

    const queryList = matchMedia && matchMedia(query)
    const updateMatch = (): void => {
      // Workaround Safari wrong implementation of matchMedia
      // TODO can we remove it?
      // https://github.com/mui-org/material-ui/pull/17315#issuecomment-528286677
      if (active && queryList) {
        setMatch(queryList.matches)
      }
    }
    updateMatch()
    queryList && queryList.addListener(updateMatch)
    return () => {
      active = false
      queryList && queryList.removeListener(updateMatch)
    }
  }, [query, matchMedia, supportMatchMedia])

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDebugValue({ query, match })
  }

  return match
}
