import { Box } from '../Box/Box'
import {
  default as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps
} from '@mui/material/CircularProgress'
import { Typography } from '../Typography/Typography'
import { forwardRef } from 'react'

export type CircularProgressProps = Pick<
  MuiCircularProgressProps,
  'value' | 'aria-labelledby' | 'size' | 'thickness'
> & {
  showPercentage?: boolean
}

export const CircularProgress = forwardRef<HTMLSpanElement, CircularProgressProps>(
  ({ value, showPercentage = false, ...rest }, ref) => {
    const circularProgressProps: MuiCircularProgressProps = {
      variant: value ? 'determinate' : 'indeterminate',
      value,
      ref,
      ...rest
    }

    return showPercentage ? (
      <Box sx={{ position: 'relative', display: 'inline-flex', width: 'min-content' }}>
        <MuiCircularProgress {...circularProgressProps} />
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant="captionMedium" component="div" color="text.secondary">
            {`${Math.round(value || 0)}%`}
          </Typography>
        </Box>
      </Box>
    ) : (
      <MuiCircularProgress {...circularProgressProps} />
    )
  }
)
CircularProgress.displayName = 'CircularProgress'
