import {
  ModalProps,
  ModalType,
  ModalTypes,
  setModalClose,
  setModalOpen
} from '../../../redux/slices/modal'
import { RootPageState } from '../../../redux/types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { usePageSelector } from '../../../hooks/usePageSelector'

export interface ModalHookReturn<ModalPropsType = ModalProps> {
  modalOpen: boolean
  props?: ModalPropsType
  setOpen(props?: ModalPropsType): void
  setClose(): void
}

export function useModal<ModalPropsType = ModalProps>(
  modalType: ModalTypes
): ModalHookReturn<ModalPropsType> {
  const dispatch = useDispatch()
  const { modals } = usePageSelector((state: RootPageState) => {
    return {
      modals: state.modalReducer.modals
    }
  })

  const modalObj = modals?.find(modal => modal.type === modalType) as
    | ModalType<ModalPropsType>
    | undefined

  const setOpen = useCallback(
    (props?: ModalPropsType): void => {
      dispatch(setModalOpen({ type: modalType, ...(props && { props }) }))
    },
    [modalType, dispatch]
  )

  const setClose = useCallback((): void => {
    dispatch(setModalClose(modalType))
  }, [dispatch, modalType])

  return {
    modalOpen: Boolean(modalObj?.open),
    setClose,
    setOpen,
    ...(modalObj?.props && { props: modalObj.props })
  }
}

export function useIsModalOpen(modalType: ModalTypes): boolean {
  const { modals } = usePageSelector((state: RootPageState) => {
    return {
      modals: state.modalReducer.modals
    }
  })
  const modalObj = modals?.find((modal: ModalType) => modal.type === modalType)

  return Boolean(modalObj?.open)
}

export function useGetOpenModals(): Partial<Record<ModalTypes, boolean>> {
  const { modals } = usePageSelector((state: RootPageState) => {
    return {
      modals: state.modalReducer.modals
    }
  })
  const modalObj = modals?.reduce((acc, val) => ({ ...acc, [val.type]: val.open }), {})

  return modalObj
}

export function useOpenModalProps(): ModalProps | null {
  const { modals } = usePageSelector((state: RootPageState) => {
    return {
      modals: state.modalReducer.modals
    }
  })

  return modals?.find((val: ModalType) => val.open)?.props || null
}
