import { GeolocationData } from '.'
import { MutableRefObject } from 'react'
import { TrackerOptions } from '../../hooks/useFlowpageAnalyticsCollector'
import { logger } from '@dtx-company/logger'

export const fetchInterstitialData = (
  geoPageViewRef: MutableRefObject<{
    trackPageView: (options?: TrackerOptions | undefined) => void
    setGeoData: (geo: GeolocationData) => void
  }>,
  headers: Record<string, string | null | undefined> | undefined,
  pageViewEventIdToOverwrite: string | undefined
): void => {
  navigator.geolocation.getCurrentPosition(
    pos => {
      geoPageViewRef.current.trackPageView({
        useHeap: true,
        headers,
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
        accuracy: pos.coords.accuracy,
        isInterstitial: true,
        eventId: pageViewEventIdToOverwrite
      })
      geoPageViewRef.current.setGeoData({
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
        accuracyMeters: pos.coords.accuracy,
        isInterstitial: true
      })
    },
    err => {
      logger.logError(`geolocation interstitial denied, ${err.code}, ${err.message}`, {
        team: 'flowlytics'
      })
    }
  )
}
