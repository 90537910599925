import { FlowpageLandingPage } from '../../components/flowpage/FlowpageLandingPage'
import { PageType, UnauthPage } from '@dtx-company/flow-codegen/src/page/typeUtils'
interface FlowpageContainerProps {
  page: PageType | UnauthPage
  referrer: string | null | undefined
}
const FlowpageSlug = ({ page, referrer }: FlowpageContainerProps): JSX.Element => {
  return <FlowpageLandingPage page={page} referrer={referrer} />
}

export const FlowpageSlugContainer = FlowpageSlug
