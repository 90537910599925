import { getCaptureServerRootUrl } from '@dtx-company/true-common/src/utils/urls/services'
import { imageConfigDefault } from 'next/dist/shared/lib/image-config'

export function getScreenshot(url: string, width: number, height: number): string | undefined {
  if (!url) {
    return undefined
  }
  if (!url.startsWith('http') && !url.startsWith('www')) {
    return undefined
  }
  const encodedUrl = encodeURIComponent(url)

  return `${getCaptureServerRootUrl()}/screenshot?url=${encodedUrl}&h=${height}&w=${width}`
}

export const generateOptimizedImagePath = ({
  src,
  width,
  height,
  quality = 75
}: {
  src: string
  width: number
  height: number
  quality?: number
}): string => {
  // do not optimize object URLs
  if (src?.startsWith('blob')) return src

  const dpr = window?.devicePixelRatio || 2
  const allowedWidth =
    imageConfigDefault.deviceSizes.find(deviceSize => deviceSize >= width * dpr) ??
    imageConfigDefault.deviceSizes[0]
  const params = new URLSearchParams({
    url: src,
    w: allowedWidth.toString(),
    ...(height && { h: (height * dpr).toString() }),
    q: quality.toString()
  })

  return `/_next/image?${params}`
}
