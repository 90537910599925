import { TWITTER_LABEL } from '@app/code/src/components/molecules/destination-icon'
import { linkTypes } from '@dtx-company/flowcode-utils/src'
export interface LinkMetaType {
  pattern: RegExp
  provider: string
  type: string
  prefix?: string
  isUsernameLink?: boolean
  title?: string
  embeddable?: boolean
}

export const fullUrlPattern =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9-]+([.]{1}[a-z0-9-]+)*\.[a-z]{2,20}(:[0-9]{1,5})?(\/.*)?$/
export enum LinkProvider {
  COLLECTION = 'collection',
  FEATURE = 'feature',
  LINK = 'link',
  CONTACT = 'contact',
  PDF = 'pdf',
  WIDGET = 'widget'
}
const featureLinks = {
  provider: LinkProvider.FEATURE,
  type: 'feature',
  pattern: fullUrlPattern
}

export default linkTypes.concat(featureLinks)

export const genericLinkMeta = {
  type: 'link',
  provider: LinkProvider.LINK,
  pattern: fullUrlPattern,
  embeddable: false
}

export type DefaultFieldType = {
  link?: string
  imageUrl?: string
  title?: string
}
export interface SocialLinkType {
  provider: string
  prefix?: string
  displayText?: string
  type?: string
  isUsernameLink?: boolean
  defaultFields?: DefaultFieldType
  adornment?: string
}
export const socialLinks: SocialLinkType[] = [
  {
    provider: 'link',
    displayText: 'URL',
    prefix: undefined,
    isUsernameLink: false
  },
  {
    provider: 'instagram',
    displayText: 'Instagram',
    prefix: 'https://www.instagram.com/',
    isUsernameLink: true
  },
  {
    provider: 'twitter',
    displayText: TWITTER_LABEL,
    prefix: 'https://www.twitter.com/',
    isUsernameLink: true
  },
  {
    provider: 'snapchat',
    displayText: 'Snapchat',
    prefix: 'https://www.snapchat.com/add/',
    isUsernameLink: true
  },
  {
    provider: 'tiktok',
    displayText: 'TikTok',
    prefix: 'https://www.tiktok.com/@',
    isUsernameLink: true
  },
  {
    provider: 'facebook',
    displayText: 'Facebook',
    prefix: 'www.facebook.com/',
    isUsernameLink: false
  },
  {
    provider: 'linkedin',
    displayText: 'LinkedIn',
    prefix: 'www.linkedin.com/',
    isUsernameLink: false
  },
  {
    provider: 'youtube',
    displayText: 'Youtube',
    prefix: 'www.youtube.com/',
    isUsernameLink: false
  },
  {
    provider: 'spotify',
    displayText: 'Spotify',
    prefix: 'www.spotify.com/',
    isUsernameLink: false
  },
  {
    provider: 'paradedeck',
    displayText: 'Parade Deck',
    prefix: 'www.paradedeck.com/',
    isUsernameLink: false
  },
  {
    provider: 'twitch',
    displayText: 'Twitch',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'patreon',
    displayText: 'Patreon',
    prefix: 'https://patreon.com/',
    isUsernameLink: false
  },
  {
    provider: 'onlyfans',
    displayText: 'OnlyFans',
    prefix: 'https://www.onlyfans.com/',
    isUsernameLink: true
  },
  {
    provider: 'venmo',
    displayText: 'Venmo',
    prefix: 'venmo://paycharge?txn=pay&recipients=',
    isUsernameLink: true
  },
  {
    provider: 'cashapp',
    displayText: 'Cash App',
    prefix: 'https://cash.app/',
    isUsernameLink: true,
    adornment: '$'
  }
]

export const homePageLinks: SocialLinkType[] = [
  ...socialLinks,
  {
    provider: 'soundcloud',
    displayText: 'SoundCloud',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'pinterest',
    displayText: 'Pinterest',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'tumblr',
    displayText: 'Tumblr',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'reddit',
    displayText: 'Reddit',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'vimeo',
    displayText: 'Vimeo',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'gofundme',
    displayText: 'GoFundMe',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'dailymotion',
    displayText: 'Daily Motion',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'ebay',
    displayText: 'ebay',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'quora',
    displayText: 'Quora',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'streamlabs',
    displayText: 'Streamlabs',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'apple-podcast',
    displayText: 'Apple Podcast',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'doordash',
    displayText: 'Doordash',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'ubereats',
    displayText: 'Uber Eats',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'caviar',
    displayText: 'Caviar',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'grubhub',
    displayText: 'GrubHub',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: 'postmates',
    displayText: 'Postmates',
    prefix: '',
    isUsernameLink: false
  }
]

// Todo: Remove once SlotWidgetTypes are stored as slots and not as links
export const SlotWidgetsOrder = 100
