import { INVITATION_ID, INVITATION_LINK_ID } from '../../components/org/constants/invitation'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { UNIFIED_ASSET_MANAGEMENT } from '@dtx-company/true-common/src/constants/featureFlags'
import { fireAnalyticsEvent } from '../../event-tracking/helpers/fireAnalyticsEvent'
import { useAuthState } from '../../hooks/useAuthState'
import { useBillingPlanType } from '@app/common/src/hooks/useBillingPlanType/useBillingPlanType'
import { useCallback, useEffect, useState } from 'react'
import { useDefaultPathname } from '../useDefaultPathname'
import { useFlowFeature } from '../useFlowFeature/useFlowFeature'
import { useHandleFlowcodeUserRedirect } from '../useHandleFlowcodeUserRedirect'
import { useIsAccountPaused } from '../useIsAccountPaused'
import { useRedirectToPausedAccountPage } from '../useRedirectToPausedAccountPage'
import { useRouter } from 'next/router'

const ssoRoutes = [Routes.SIGN_IN, Routes.SIGN_UP]

export function useRedirectOnAuthChanges(
  authRequired = false,
  shouldRedirectToSignUp = false
): void {
  const router = useRouter()
  const currentPageIsSSORoute = ssoRoutes.includes(router.pathname as Routes)
  const currentPageIsSSOLogin = router.pathname === Routes.SSO_LOGIN
  const handleFlowcodeUserRedirect = useHandleFlowcodeUserRedirect()
  const redirectTo = useRedirectTo()
  const isAccountPaused = useIsAccountPaused()
  const redirectToPausedAccountPage = useRedirectToPausedAccountPage()
  const [redirectCalled, setRedirectCalled] = useState(false)
  const { isAuthenticated, jwt, isAuthChecked } = useAuthState()
  const defaultPathname = useDefaultPathname()
  const orgStatus = jwt?.org?.status
  const invitationId = (router.query.invitationId ?? '')?.toString()
  const invitationLinkId = (router.query.inviteId ?? '')?.toString()

  const [isUnifiedAssetManagementEnabled] = useFlowFeature(UNIFIED_ASSET_MANAGEMENT, {
    autoUpdate: true
  })
  const billingPlanType = useBillingPlanType()
  const isEnterprise = billingPlanType === PlanType.ENTERPRISE

  useEffect(() => {
    if (!isAuthChecked) return
    if (redirectCalled) {
      return
    }
    if (isAuthenticated && currentPageIsSSORoute) {
      fireAnalyticsEvent('useRedirectOnAuthChanges_isAuthenticated_and_currentPageIsSSORoute')
      handleFlowcodeUserRedirect()
      setRedirectCalled(true)
    } else if (authRequired && !isAuthenticated) {
      fireAnalyticsEvent('useRedirectOnAuthChanges_authRequired_and_not_authenticated')
      if (invitationId && router.pathname === Routes.ACCEPT_INVITE) {
        fireAnalyticsEvent('useRedirectOnAuthChanges_invitationId_and_accept_invite_route')
        localStorage.setItem(INVITATION_ID, invitationId?.toString())
      }
      if (invitationLinkId && router.pathname === Routes.ACCEPT_ORG_INVITE) {
        fireAnalyticsEvent('useRedirectOnAuthChanges_invitationLinkId_and_accept_org_invite_route')
        localStorage.setItem(INVITATION_LINK_ID, invitationLinkId?.toString())
      }
      if (shouldRedirectToSignUp) {
        fireAnalyticsEvent('useRedirectOnAuthChanges_shouldRedirectToSignUp')
        redirectTo({ pathname: Routes.SIGN_UP, replace: true })
      } else {
        fireAnalyticsEvent('useRedirectOnAuthChanges_redirectToSignIn')
        redirectTo({ pathname: Routes.SIGN_IN, replace: true })
      }
      setRedirectCalled(true)
    } else if (isAccountPaused && authRequired) {
      fireAnalyticsEvent('useRedirectOnAuthChanges_isAccountPaused_and_authRequired')
      redirectToPausedAccountPage()
      setRedirectCalled(true)
    }
  }, [
    redirectCalled,
    setRedirectCalled,
    currentPageIsSSOLogin,
    isAuthChecked,
    isAuthenticated,
    router,
    handleFlowcodeUserRedirect,
    currentPageIsSSORoute,
    authRequired,
    redirectTo,
    orgStatus,
    invitationId,
    invitationLinkId,
    shouldRedirectToSignUp,
    isAccountPaused,
    redirectToPausedAccountPage,
    isUnifiedAssetManagementEnabled,
    isEnterprise,
    defaultPathname
  ])
}

function useRedirectTo(): ({ pathname, replace }: { pathname: string; replace?: boolean }) => void {
  const router = useRouter()
  return useCallback(
    ({ pathname, replace }) => {
      router[replace ? 'replace' : 'push']({
        pathname,
        query: { ...router.query, redirectTo: router.asPath }
      })
    },
    [router]
  )
}
