/**
 * @deprecated use `theme.breakpoints` from packages/design-system/src/theme/index.ts instead
 */
export enum SIZES {
  mobile = 375,
  mdMobile = 576,
  tablet = 768,
  largeTablet = 832,
  desktop = 1024,
  largeDesktop = 1280
}
const customMediaQuery = (minWidth: number): string => `@media (min-width: ${minWidth}px)`
const customMaxMediaQuery = (maxWidth: number): string => `@media (max-width: ${maxWidth}px)`

/**
 * @deprecated use `theme.breakpoints` from packages/design-system/src/theme/index.ts instead
 */
export const mediaMaxWidth = {
  custom: customMaxMediaQuery,
  mobile: customMaxMediaQuery(SIZES.mobile),
  mdMobile: customMaxMediaQuery(SIZES.mdMobile),
  tablet: customMaxMediaQuery(SIZES.tablet),
  desktop: customMaxMediaQuery(SIZES.desktop),
  largeDesktop: customMaxMediaQuery(SIZES.largeDesktop)
}

/**
 * @deprecated use `theme.breakpoints` from packages/design-system/src/theme/index.ts instead
 */
export const media = {
  custom: customMediaQuery,
  mobile: customMediaQuery(SIZES.mobile),
  tablet: customMediaQuery(SIZES.tablet),
  desktop: customMediaQuery(SIZES.desktop),
  largeDesktop: customMediaQuery(SIZES.largeDesktop)
}
