import { GeolocationData } from '../../components/PageTracker'
import { Maybe } from '@dtx-company/flow-codegen/src/page/generated.types'
import { UnauthPageQueryTheme, UnauthPageSlots } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { createContext } from 'react'

export interface PageDisplayState {
  pixelPassthrough: boolean
  setPixelPassthrough: (passthrough: boolean) => void
  utmPassthrough: boolean
  setUtmPassthrough: (passthrough: boolean) => void
  unauthTheme?: UnauthPageQueryTheme
  setUnauthTheme: (unauthTheme: UnauthPageQueryTheme | undefined) => void
  gateConditionComplete: boolean
  isGateCRMFormSubmitted: boolean
  setIsGateCRMFormSubmitted: (submitted: boolean) => void
  setGateConditionComplete: (completed: boolean) => void
  isOwnPage: boolean
  setIsOwnPage: (ownPage: boolean) => void
  geoData?: GeolocationData
  setGeoData: (geo: GeolocationData) => void
  page: {
    id?: string
    displayName?: Maybe<string>
    slugName?: Maybe<string>
    slots?: UnauthPageSlots
  }
}

export const context = createContext<PageDisplayState | undefined>(undefined)
context.displayName = 'PageDisplayContext'
