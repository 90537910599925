import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'

export function getCurrentPageGate(links: LinkType[] | null | undefined): LinkType | undefined {
  return links?.find(link => {
    if (!link.active) {
      return false
    }
    switch (link.type) {
      case 'ageGate':
      case 'nbaIdGate':
        return true
      case 'contactCollection':
        if (link.displayType === 'popup') {
          return true
        }
    }
    return false
  })
}
