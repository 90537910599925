export function capitalize(s: string): string {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const HASH_REGEX = /#[^?]*/
export function getHash(path: string): string | null {
  return HASH_REGEX.exec(path)?.[0]?.slice(1) || null
}

const HEX_REGEX = /^#[0-9A-Fa-f]{0,8}$/i
export const validateHexInput = (input: string): boolean => {
  return HEX_REGEX.test(input)
}

export const generateRandomString = (length = 4): string =>
  Math.random().toString(20).substr(2, length)

export const checkPatternAndPrefix = (
  val: string,
  pattern?: RegExp,
  prefix?: string
): [true | RegExpMatchArray | null, string] => {
  const matchesPattern = pattern && val.length ? val.match(pattern) : true
  let rawValue = val || ''
  if (val && prefix?.length && val.startsWith(prefix)) {
    rawValue = val.replace(prefix, '')
  }
  const modifiedValue = prefix && rawValue.length ? prefix + rawValue : rawValue
  return [matchesPattern, modifiedValue]
}

export const sentenceCase = (str: string): string => {
  const firstChar = str.substring(0, 1).toUpperCase()
  const remainder = str.substring(1).toLowerCase()
  return firstChar + remainder
}

export const decodeBase64String = (data: string): string => {
  if (!data) return ''
  //on the serverside window is not defined
  return typeof window === 'undefined' ? Buffer.from(data, 'base64').toString() : window.atob(data)
}

export const getIdFromEncodedString = (data: string): string =>
  decodeBase64String(data).split(':')[1]
