import { IS_PRODUCTION } from '@dtx-company/true-common/src/constants/env'

export const ONE_TRUST_DOMAIN_SCRIPT = IS_PRODUCTION
  ? 'eed4f849-6744-46e9-8b81-04d191129887'
  : 'eed4f849-6744-46e9-8b81-04d191129887-test'

export const FACEBOOK_APP_ID = '931207947304452'

// Used to tag assets that Cloudflare caches and are cleared on deploy
export const APP_CACHE_TAG = 'app'
