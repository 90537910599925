import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DEFAULT_AVATAR_DARK } from '../../constants/profile'
import { FC } from 'react'
import { FlowpageContainer } from '../flowpage/FlowPageContent/styles'
import { FontFamilies } from '@dtx-company/true-common/src/constants/page'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { theme as appTheme } from '@dtx-company/shared-components/src'
import { getCustomThemeValues } from '../../utils/theme'
import styled, { DefaultTheme, ThemeProvider } from 'styled-components'

const StyledBGImage = styled(Image)`
    opacity: .5;
    position: fixed;
    width: 100%;
    height 100%;
    img {
      object-fit: cover;
    }`

const BlurWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  filter: blur(10px);
  margin: 0 auto;
  position: relative;
`

const BlankLink = styled(Box)`
  background-color: grey;
  border-radius: 12px;
  width: 100%;
  height: 40px;
  margin: 16px 0px;
`

export const BlurredPagePreview: FC<Partial<PageType> & { linksLength: number }> = ({
  theme,
  profileImage,
  displayName,
  caption,
  linksLength
}) => {
  const { style, contrastText } = getCustomThemeValues(theme)
  const userTheme = {
    ...theme,
    fontFamily: FontFamilies.Inter,
    colors: {
      ...appTheme.colors
    }
  }
  return (
    <ThemeProvider theme={userTheme as DefaultTheme}>
      <FlowpageContainer bg={style} minHeight={'100%'}>
        <Spacer mt="36px" />
        {theme?.backgroundImgUrl && (
          <StyledBGImage
            src={theme?.backgroundImgUrl}
            alt={displayName || 'blurred-page-prof-image'}
          />
        )}
        <BlurWrapper>
          <Image
            width="140px"
            height="140px"
            mb="16px"
            borderRadius={'50%'}
            src={profileImage || DEFAULT_AVATAR_DARK}
          />
          <Box alignItems="center">
            <Text color={contrastText} variant="display/large" textAlign="center">
              {displayName}
            </Text>
          </Box>
          <Spacer mb="8px" />
          <Text color={contrastText} variant="body/medium" textAlign="center">
            {caption}
          </Text>
          {Array(linksLength).map(_blank => (
            <BlankLink key={_blank} />
          ))}
        </BlurWrapper>
      </FlowpageContainer>
    </ThemeProvider>
  )
}
