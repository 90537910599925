import { Meta, meta } from '../constants/meta'

export const getPageMetaAttributes = (page?: string): Meta => {
  const pageMeta = page && meta[page]

  return {
    ...meta.default,
    ...(!!pageMeta && pageMeta)
  }
}
