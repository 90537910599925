import { FooterTextLink } from '../FooterLink'
import { MarketingPageRoutes } from '@dtx-company/true-common/src/constants/routes'

export const footerData: FooterTextLink[] = [
  {
    href: MarketingPageRoutes.PRIVACY_POLICY,
    label: 'Privacy Policy'
  },
  {
    href: MarketingPageRoutes.TERMS_OF_USE,
    label: 'Terms of Use'
  }
]
